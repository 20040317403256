<template>
  <el-dialog
    v-model="showDialog"
    :title="$t.translate('TTL_CONFIRM_ACTION')"
    custom-class="cus-cont"
  >
    <p>{{ $t.translate("MSG_CONFIRM_EXIT_APPLICATION") }}</p>

    <template #footer>
      <span class="dialog-footer" style="display: flex; justify-content: space-between">
        <div>
          <el-button type="primary" @click="dialogResponse('save')">{{
            $t.translate("BTN_SAVE_APP")
          }}</el-button>

          <el-button type="danger" @click="dialogResponse('discard')">{{
            $t.translate("BTN_DONT_SAVE_APP")
          }}</el-button>
        </div>

        <el-button @click="dialogResponse('cancel')">{{
          $t.translate("BTN_CANCEL")
        }}</el-button>
      </span>
    </template>
  </el-dialog>

  <div id="stepTopView" class="page-custom page-independent-alt container-fluid pb-5">
    <div class="pt-4"></div>

    <div class="container mt-2">
      <!-- <p class="h3 mb-4 text-capitalize text-dark px-md-3 px-lg-6 text-center"></p> -->

      <template v-if="true">
        <el-steps :active="currentStep" align-center>
          <template v-for="(item, index) in Steps" :key="index">
            <el-step
              :title="mq.current == 'xsmall' ? '' : $t.translate(item.Label)"
              @click="navStep(index)"
              :class="{
                'c-pointer': currentStep != index && stepStatus[index.toString()],
              }"
              :status="
                currentStep == index
                  ? 'process'
                  : stepStatus[index.toString()]
                  ? 'finish'
                  : 'wait'
              "
            >
            </el-step>
          </template>
        </el-steps>
      </template>

      <div v-if="currentMode == 'proposal'" class="shadow bg-white p-around mt-3">
        <div class="form-cont">
          <p class="sec-head">{{ $t.translate("TTL_PROPOSAL") }}</p>

          <div class="sec-div row">
            <div class="col-md-11">
              <p class="form-control-label">
                {{ $t.translate("FRM_TYPE_OF_SERVICE") }}
              </p>

              <el-select
                :disabled="
                  formData.ApplicationKey != '' || formData.MachformEntryKey != ''
                "
                v-model="formData.AssessmentServiceKey"
                :placeholder="$t.translate('LBL_PLS_SELECT')"
                class="w-100 mb-3"
                popper-class="select-pop"
                @change="changeService"
                :class="{ 'inp-invalid': $h.isBlank(formData.AssessmentServiceKey) }"
              >
                <el-option
                  v-for="item in ServicesList"
                  :key="'assessment-ser-' + item.ServiceKey"
                  :label="
                    $h.formatName(item, 'Name') +
                    ($h.formatName(item, 'NameExtra', true)
                      ? ' - ' + $h.formatName(item, 'NameExtra')
                      : '')
                  "
                  :value="item.ServiceKey"
                >
                </el-option>
              </el-select>
            </div>

            <template v-if="BusinessStaff?.Email">
              <div class="col-md-11">
                <p class="mb-2">
                  <b>{{ $t.translate("FRM_REP_BUSIN_STAFF") }}</b>
                </p>
              </div>

              <div class="col-md-5">
                <p class="form-control-label">{{ $t.translate("FRM_NAME") }}</p>
                <div class="block">
                  <base-input
                    :value="$h.formatName(BusinessStaff, 'DisplayName')"
                    disabled
                    form-classes="mb-3"
                    placeholder="---"
                  />
                </div>
              </div>
              <div class="col-md-5">
                <p class="form-control-label">{{ $t.translate("FRM_POSITION") }}</p>
                <div class="block">
                  <base-input
                    v-model="BusinessStaff.Position"
                    disabled
                    form-classes="mb-3"
                    placeholder="---"
                  />
                </div>
              </div>
              <div class="col-md-5">
                <p class="form-control-label">{{ $t.translate("FRM_TEL") }}</p>
                <div class="block">
                  <el-input v-model="BusinessStaff.MobileNumber" disabled class="mb-3">
                    <template #prepend>
                      <CountryCode disabled v-model="BusinessStaff.MobileCode" />
                    </template>
                  </el-input>
                </div>
              </div>
              <div class="col-md-5">
                <p class="form-control-label">{{ $t.translate("FRM_EMAIL") }}</p>
                <div class="block">
                  <base-input
                    v-model="BusinessStaff.Email"
                    disabled
                    form-classes="mb-3"
                  />
                </div>
              </div>

              <div class="col-md-10">
                <p class="mb-3">{{ $t.translate("MSG_CONTACT_BUSINESS_STAFF_TXT") }}</p>
              </div>
            </template>

            <div class="col-md-10">
              <p class="form-control-label">
                {{ $t.translate("FRM_PROPOSAL_QUOTA_NO") }}
              </p>
              <base-input
                show-word-limit
                :maxlength="$h.getCharLimit().Propsal"
                v-model="formData.ProposalNo"
                :valid="!$h.isBlank(formData.ProposalNo)"
                form-classes="mb-3"
              />

              <p class="form-control-label">
                {{ $t.translate("FRM_PROPOSAL_QUOTA_BY_SYSTEM")
                }}<span class="required">*</span>
              </p>
              <span class="text-helper pre-line mb-0">{{
                $t.translate("LBL_UPLOAD_FILE_LIMIT")
              }}</span>
              <span class="text-helper pre-line">{{
                $t.swapValues(
                  {
                    SPECIAL_CHARS: $h.getSpecialChars(),
                  },
                  $t.translate("LBL_UPLOAD_FILE_NAME_LIMIT")
                )
              }}</span>
              <div class="mb-3 mt-2 d-flex align-items-center">
                <div v-if="formData?.ProposalFile?.File" class="mr-3">
                  <a
                    class="c-pointer"
                    target="_blank"
                    :href="
                      $axios.formulateUrl(
                        '/export/download?Token=' + formData?.ProposalFile?.DownloadToken
                      )
                    "
                    >{{ formData.ProposalFile.File }}</a
                  >
                </div>
                <el-upload
                  :show-file-list="false"
                  :action="
                    $axios.formulateUrl(
                      `/media/upload/tmp?general=1${
                        formData.ApplicationKey
                          ? `&ApplicationKey=${formData.ApplicationKey}`
                          : ``
                      }`
                    )
                  "
                  :headers="$axios.getHeaders()"
                  :on-error="$axios.uploadError"
                  :on-progress="$axios.uploadProgress"
                  :on-success="
                    (res, file) => sucUploadProposal(res, file, $axios.doneUpload)
                  "
                  :accept="$h.getGeneralFileTypes()"
                >
                  <el-button
                    size="small"
                    class="c-pointer"
                    :class="{ 'inp-invalid': $h.isBlank(formData?.ProposalFile?.File) }"
                    >{{ $t.translate("BTN_UPLOAD") }}</el-button
                  >
                </el-upload>
              </div>
            </div>

            <!--<div class="col-md-11">
              <el-radio-group
                :class="{ 'inp-invalid': $h.isBlank(formData.PersonTitle) }"
                v-model="formData.PersonTitle"
              >
                <el-radio
                  v-for="item in PersonTitleOpts"
                  :key="item.value"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>

              <p class="form-control-label">
                {{ $t.translate("LBL_MANAGEMENT_REPRESENTATIVE") }}
              </p>
              <base-input
                v-model="formData.Representative"
                :valid="!$h.isBlank(formData.Representative)"
                form-classes="mb-3"
              />
            </div>

            <div class="col-md-11">
              <p class="form-control-label">{{ $t.translate("FRM_EMAIL") }}</p>
              <base-input
                v-model="formData.RepEmail"
                :valid="
                  !$h.isBlank(formData.RepEmail) && $h.validateEmail(formData.RepEmail)
                "
                form-classes="mb-3"
              />
            </div>

            <div class="col-md-11">
              <p class="form-control-label">{{ $t.translate("FRM_POSITION") }}</p>
              <base-input
                v-model="formData.RepPosition"
                :valid="!$h.isBlank(formData.RepPosition)"
                form-classes="mb-3"
              />
            </div>-->

            <div class="col-md-11">
              <div class="form-control-label">{{ $t.translate("FRM_CURRENCY") }}</div>
              <div class="mb-4 d-flex">
                <el-select
                  :class="{ 'inp-invalid': $h.isBlank(formData.Currency) }"
                  v-model="formData.Currency"
                  :placeholder="$t.translate('LBL_PLS_SELECT')"
                  @change="changeAmount"
                  class="currency-label"
                >
                  <el-option
                    v-for="item in CurrencyOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="col-md-11">
              <p class="form-control-label">{{ $t.translate("FRM_APPLICATION_FEE") }}</p>
              <div class="mb-4 d-flex align-items-center">
                <!-- {{
                  $h.formatPrice(
                    formData.ApplicationFee,
                    $h.mapLabel(formData.Currency, CurrencyOptions)
                  )
                }} -->
                <!-- client required to be able to edit the fees -->

                <span class="mr-2">{{
                  $h.mapLabel(formData.Currency, CurrencyOptions)
                }}</span>
                <el-input-number
                  v-model="formData.ApplicationFee"
                  controls-position="right"
                  :min="0"
                >
                </el-input-number>
              </div>

              <p class="form-control-label">{{ $t.translate("FRM_ASSESSMENT_FEE") }}</p>
              <div class="mb-4 d-flex align-items-center">
                <span class="mr-2">{{
                  $h.mapLabel(formData.Currency, CurrencyOptions)
                }}</span>
                <el-input-number
                  v-model="formData.AssessmentFee"
                  controls-position="right"
                  :min="0"
                >
                </el-input-number>
              </div>

              <p class="form-control-label">
                {{ $t.translate("FRM_TOTAL_REGISTRATION_FEE") }}
              </p>
              <div class="mb-4 d-flex">
                {{
                  $h.formatPrice(
                    TotalRegisFee,
                    $h.mapLabel(formData.Currency, CurrencyOptions)
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentMode == 'applicant'" class="shadow bg-white p-around mt-3">
        <div class="form-cont">
          <p class="sec-head">{{ $t.translate("TTL_APPLICANT_INFO") }}</p>

          <div class="sec-div row">
            <div class="col-md-11">
              <p class="form-control-label">
                {{ $t.translate("FRM_COMPANY_NAME_SAME_BUSINESS_REG") }}
                {{ $t.translate("LANG_EN") }}
              </p>
              <el-input v-model="formData.Company" disabled class="mb-3" />
            </div>
            <div class="col-md-11">
              <p class="form-control-label">
                {{ $t.translate("FRM_COMPANY_NAME_SAME_BUSINESS_REG") }}
                {{ $t.translate("LANG_CHT") }}
              </p>
              <el-input v-model="formData.CompanyCHT" disabled class="mb-3" />
            </div>
            <div class="col-md-11">
              <p class="form-control-label">
                {{ $t.translate("FRM_COMPANY_NAME_SAME_BUSINESS_REG") }}
                {{ $t.translate("LANG_CHS") }}
              </p>
              <el-input v-model="formData.CompanyCHS" disabled class="mb-3" />
            </div>

            <div class="col-md-11">
              <p class="form-control-label">
                {{ $t.translate("FRM_BRANCH_IFANY") }} {{ $t.translate("LANG_EN") }}
              </p>
              <el-input
                :maxlength="$h.getCharLimit().BranchName - (formData.Company.length + 1)"
                show-word-limit
                v-model="formData.BranchName"
                :disabled="formData.IsLocked"
                class="mb-3"
              />
            </div>
            <div class="col-md-11">
              <p class="form-control-label">
                {{ $t.translate("FRM_BRANCH_IFANY") }} {{ $t.translate("LANG_CHT") }}
              </p>
              <el-input
                :maxlength="
                  $h.getCharLimit().BranchNameCHT - (formData.CompanyCHT.length + 1)
                "
                show-word-limit
                v-model="formData.BranchNameCHT"
                :disabled="formData.IsLocked"
                class="mb-3"
              />
            </div>
            <div class="col-md-11">
              <p class="form-control-label">
                {{ $t.translate("FRM_BRANCH_IFANY") }} {{ $t.translate("LANG_CHS") }}
              </p>
              <el-input
                :maxlength="
                  $h.getCharLimit().BranchNameCHS - (formData.CompanyCHS.length + 1)
                "
                show-word-limit
                v-model="formData.BranchNameCHS"
                :disabled="formData.IsLocked"
                class="mb-3"
              />
            </div>

            <div class="col-md-11">
              <p class="form-control-label">{{ $t.translate("FRM_REGION") }}</p>
              <el-select
                disabled
                class="w-100 mb-3"
                v-model="formData.Region"
                :placeholder="$t.translate('LBL_PLS_SELECT')"
              >
                <el-option
                  v-for="item in RegionList"
                  :key="item.Value"
                  :label="item.Label"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-md-11">
              <p class="form-control-label">
                {{ $t.translate("LBL_ORGANIZATION_ADDRESS") }}
                {{ $t.translate("LANG_EN") }}
              </p>
              <el-input
                show-word-limit
                :maxlength="$h.getCharLimit().Address"
                :disabled="formData.IsOrgAddressDisabled"
                v-model="formData.Address"
                :rows="2"
                type="textarea"
                class="mb-3"
              />
            </div>
            <div class="col-md-11">
              <p class="form-control-label">
                {{ $t.translate("LBL_ORGANIZATION_ADDRESS") }}
                {{ $t.translate("LANG_CHT") }}
              </p>
              <el-input
                show-word-limit
                :maxlength="$h.getCharLimit().AddressCHT"
                :disabled="formData.IsOrgAddressDisabled"
                v-model="formData.AddressCHT"
                :rows="2"
                type="textarea"
                class="mb-3"
              />
            </div>
            <div class="col-md-11">
              <p class="form-control-label">
                {{ $t.translate("LBL_ORGANIZATION_ADDRESS") }}
                {{ $t.translate("LANG_CHS") }}
              </p>
              <el-input
                show-word-limit
                :maxlength="$h.getCharLimit().AddressCHS"
                :disabled="formData.IsOrgAddressDisabled"
                v-model="formData.AddressCHS"
                :rows="2"
                type="textarea"
                class="mb-3"
              />
            </div>

            <div class="col-md-11">
              <p class="form-control-label">
                {{ $t.translate("FRM_CORRESPONDING_ADDRESS_IF_DIFFERENT") }}
              </p>
              <el-input
                show-word-limit
                :maxlength="$h.getCharLimit().Address"
                v-model="formData.CorrespondingAddress"
                :rows="2"
                type="textarea"
                class="mb-3"
              />
            </div>

            <div class="col-md-11">
              <p class="form-control-label">{{ $t.translate("FRM_INDUSTRY") }}</p>
              <el-select
                disabled
                class="w-100 mb-3"
                v-model="formData.IndustryId"
                :placeholder="$t.translate('LBL_PLS_SELECT')"
              >
                <el-option
                  v-for="item in IndustryList"
                  :key="item.IndustryId"
                  :label="$h.mapFieldByCC(item, 'Name')"
                  :value="item.IndustryId"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <p class="form-control-label">
                    {{ $t.translate("FRM_GENERAL_TEL_LINE") }}
                  </p>
                  <el-input
                    show-word-limit
                    :maxlength="$h.getCharLimit().PhoneNumber"
                    v-model="formData.MobileNumber"
                    class="mb-3"
                    :class="{
                      'inp-invalid':
                        $h.isBlank(formData.MobileNumber) ||
                        !$h.isNumeric(formData.MobileNumber),
                    }"
                  >
                    <template #prepend>
                      <CountryCode v-model="formData.MobileCode" />
                    </template>
                  </el-input>
                </div>

                <div class="col-md-6">
                  <p class="form-control-label">{{ $t.translate("FRM_FAX") }}</p>
                  <base-input
                    show-word-limit
                    :maxlength="$h.getCharLimit().Fax"
                    v-model="formData.Fax"
                    form-classes="mb-3"
                  />
                </div>

                <div class="col-md-6">
                  <p class="form-control-label">
                    {{ $t.translate("FRM_GENERAL_EMAIL") }}
                  </p>
                  <base-input
                    show-word-limit
                    :maxlength="$h.getCharLimit().Email"
                    v-model="formData.Email"
                    :valid="
                      !$h.isBlank(formData.Email) && $h.validateEmail(formData.Email)
                    "
                    form-classes="mb-3"
                  />
                </div>

                <div class="col-md-6">
                  <p class="form-control-label">
                    {{ $t.translate("FRM_WEBSITE") }} ({{ $t.translate("LBL_IF_ANY") }})
                  </p>
                  <base-input
                    show-word-limit
                    :maxlength="$h.getCharLimit().Website"
                    v-model="formData.Website"
                    form-classes="mb-3"
                  />
                </div>

                <div class="col-md-12">
                  <p class="form-control-label">
                    {{ $t.translate("FRM_SCOPE") }} {{ $t.translate("LANG_EN") }}
                  </p>

                  <el-alert
                    :title="
                      $t
                        .translate('LBL_THE_SCOPE_REQUIRMENT_CHART_LIMIT')
                        .replace('{COUNT}', $h.getMaxCharScopeRequirement('EN', true))
                    "
                    show-icon
                    :closable="false"
                    :style="{ width: $h.getWidth('EN') }"
                  ></el-alert>

                  <div class="pos-relative" :style="{ width: $h.getWidth('EN') }">
                    <el-input
                      v-model="formData.Scope"
                      type="textarea"
                      @input="(val) => updateScope(val, 'EN')"
                      :autosize="{
                        minRows: $h.getScopeRequirement().rLimit,
                        maxRows: $h.getScopeRequirement().rLimit,
                      }"
                      :class="{
                        'inp-invalid': $h.isBlank(formData.Scope),
                      }"
                      class="mb-3"
                      :disabled="formData.IsScopeDisabled"
                    />

                    <span class="custom-text-count"
                      >{{ $h.getTotalCount(formData.Scope) }}/{{
                        $h.getMaxCharScopeRequirement("EN")
                      }}</span
                    >
                  </div>
                </div>
                <div class="col-md-12">
                  <p class="form-control-label">
                    {{ $t.translate("FRM_SCOPE") }} {{ $t.translate("LANG_CHT") }}
                  </p>

                  <el-alert
                    :title="
                      $t
                        .translate('LBL_THE_SCOPE_REQUIRMENT_CHART_LIMIT')
                        .replace('{COUNT}', $h.getMaxCharScopeRequirement('CHT', true))
                    "
                    show-icon
                    :closable="false"
                    :style="{ width: $h.getWidth('CHT') }"
                  ></el-alert>

                  <div class="pos-relative" :style="{ width: $h.getWidth('CHT') }">
                    <el-input
                      v-model="formData.ScopeCHT"
                      type="textarea"
                      @input="(val) => updateScope(val, 'CHT')"
                      :autosize="{
                        minRows: $h.getScopeRequirement().rLimit,
                        maxRows: $h.getScopeRequirement().rLimit,
                      }"
                      :class="{
                        'inp-invalid': $h.isBlank(formData.ScopeCHT),
                      }"
                      class="mb-3"
                      :disabled="formData.IsScopeDisabled"
                    />

                    <span class="custom-text-count"
                      >{{ $h.getTotalCount(formData.ScopeCHT) }}/{{
                        $h.getMaxCharScopeRequirement("CHT")
                      }}</span
                    >
                  </div>
                </div>
                <div class="col-md-12">
                  <p class="form-control-label">
                    {{ $t.translate("FRM_SCOPE") }} {{ $t.translate("LANG_CHS") }}
                  </p>
                  <el-alert
                    :title="
                      $t
                        .translate('LBL_THE_SCOPE_REQUIRMENT_CHART_LIMIT')
                        .replace('{COUNT}', $h.getMaxCharScopeRequirement('CHS', true))
                    "
                    show-icon
                    :closable="false"
                    :style="{ width: $h.getWidth('CHS') }"
                  ></el-alert>

                  <div class="pos-relative" :style="{ width: $h.getWidth('CHS') }">
                    <el-input
                      v-model="formData.ScopeCHS"
                      type="textarea"
                      @input="(val) => updateScope(val, 'CHS')"
                      :autosize="{
                        minRows: $h.getScopeRequirement().rLimit,
                        maxRows: $h.getScopeRequirement().rLimit,
                      }"
                      :class="{
                        'inp-invalid': $h.isBlank(formData.ScopeCHS),
                      }"
                      class="mb-3 dynamic-width-input"
                      :disabled="formData.IsScopeDisabled"
                    />

                    <span class="custom-text-count"
                      >{{ $h.getTotalCount(formData.ScopeCHS) }}/{{
                        $h.getMaxCharScopeRequirement("CHS")
                      }}</span
                    >
                  </div>
                </div>

                <div class="col-md-12">
                  <p class="form-control-label">
                    {{ $t.translate("FRM_SCOPE_CATG") }} ({{
                      $t.translate("LBL_IF_ANY")
                    }})
                  </p>
                  <base-input
                    show-word-limit
                    :maxlength="$h.getCharLimit().ScopeCategory"
                    v-model="formData.ScopeCategory"
                    :disabled="formData.IsScopeDisabled"
                    form-classes="mb-3"
                  />
                </div>

                <div class="col-md-12">
                  <p class="form-control-label">
                    {{ $t.translate("FRM_SITE_ADDR_IFANY") }}
                    {{ $t.translate("LANG_EN") }}
                  </p>
                  <base-input
                    show-word-limit
                    :maxlength="$h.getCharLimit().Address"
                    v-model="formData.ScopeAddress"
                    :disabled="formData.IsScopeSiteAddressDisabled"
                    form-classes="mb-3"
                  />
                </div>
                <div class="col-md-12">
                  <p class="form-control-label">
                    {{ $t.translate("FRM_SITE_ADDR_IFANY") }}
                    {{ $t.translate("LANG_CHT") }}
                  </p>
                  <base-input
                    show-word-limit
                    :maxlength="$h.getCharLimit().AddressCHT"
                    v-model="formData.ScopeAddressCHT"
                    :disabled="formData.IsScopeSiteAddressDisabled"
                    form-classes="mb-3"
                  />
                </div>
                <div class="col-md-12">
                  <p class="form-control-label">
                    {{ $t.translate("FRM_SITE_ADDR_IFANY") }}
                    {{ $t.translate("LANG_CHS") }}
                  </p>
                  <base-input
                    show-word-limit
                    :maxlength="$h.getCharLimit().AddressCHS"
                    v-model="formData.ScopeAddressCHS"
                    :disabled="formData.IsScopeSiteAddressDisabled"
                    form-classes="mb-3"
                  />
                </div>

                <div class="col-md-12">
                  <p class="form-control-label">
                    {{ $t.translate("FRM_LANG_PREFER_FOR_ASM") }}
                  </p>
                  <el-select
                    :class="{
                      'inp-invalid': $h.isBlank(formData.PreferredAssessmentLang),
                    }"
                    v-model="formData.PreferredAssessmentLang"
                    :placeholder="$t.translate('LBL_PLS_SELECT')"
                  >
                    <el-option
                      v-for="item in LangList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>

          <!--<p class="sec-head mt-3">{{ $t.translate("TTL_REGIS_INFO") }}</p>
          <p>
            <b>{{ $t.translate("TTL_DEFINE_BOUNDARY_PROGRAM") }}</b>
          </p>
          <div class="sec-div row" style="margin-left: -5px">
            <div class="col-md-11">
              <el-radio-group
                :class="{ 'inp-invalid': $h.isBlank(formData.RegisBoundary) }"
                v-model="formData.RegisBoundary"
                @change="changeBoundary"
              >
                <template v-for="(item, index) in BoundaryProgram" :key="index">
                  <el-radio :label="item.value" class="w-100">
                    <b>{{ $t.translate(item.label) }}</b>
                  </el-radio>
                  <div v-if="regisFieldChecked(item.value)">
                    <p class="form-control-label">
                      {{ $t.translate(item.specificLabel) }}
                    </p>
                    <el-input
                      v-model="formData.regisInfo[item.specific]"
                      :rows="2"
                      type="textarea"
                      class="mb-3"
                      :class="{
                        'inp-invalid': $h.isBlank(formData.regisInfo[item.specific]),
                      }"
                    />

                    <template v-if="item.radio">
                      <p class="form-control-label mb-1">
                        {{ $t.translate(item.radioLabel) }}
                      </p>
                      <el-radio-group
                        v-model="formData.regisInfo[item.radio]"
                        class="ml-3 mb-3"
                      >
                        <el-radio
                          v-for="opt in item.radioList"
                          :key="opt.value"
                          :label="opt.value"
                        >
                          {{ $t.translate(opt.label) }}
                        </el-radio>
                      </el-radio-group>
                    </template>
                  </div>
                </template>
              </el-radio-group>
            </div>
          </div>-->
        </div>
      </div>

      <div v-if="currentMode == 'contact'" class="shadow bg-white p-around mt-3">
        <div class="form-cont">
          <p class="sec-head">{{ $t.translate("TTL_CONTACT_INFO") }}</p>

          <div class="sec-div row">
            <div
              v-for="(person, index) in formData.contactInfo"
              :key="index"
              class="col-md-11 mb-2"
            >
              <div class="contact-info-box-reps">
                <p class="mb-2">
                  <b>{{ $t.translate(person.Label) }}</b>
                </p>
                <form>
                  <div class="row">
                    <div v-if="TypeOptions(person)?.length" class="col-md-12 mb-1">
                      <p class="form-control-label mb-1">
                        {{ $t.translate("FRM_IS_SAME_AS") }}
                      </p>
                      <el-radio-group v-model="person.IsSameAsType">
                        <el-radio
                          class="mr-3"
                          v-for="pitem in TypeOptions(person)"
                          :key="pitem.Type"
                          :label="pitem.Type"
                        >
                          {{ $t.translate(pitem.Label) }}
                        </el-radio>
                      </el-radio-group>
                    </div>

                    <template v-if="!person?.IsSameAsType">
                      <div class="col-md-12">
                        <el-radio-group
                          v-model="person.PersonTitle"
                          :class="{
                            'inp-invalid':
                              $h.isBlank(person.PersonTitle) && isRequired(person),
                          }"
                        >
                          <el-radio
                            v-for="item in PersonTitleOpts"
                            :key="item.value"
                            :label="item.value"
                          >
                            {{ $t.translate(item.label) }}
                          </el-radio>
                        </el-radio-group>
                      </div>

                      <div class="col-md-6">
                        <p class="form-control-label">{{ $t.translate("FRM_NAME") }}</p>
                        <el-input
                          show-word-limit
                          :maxlength="$h.getCharLimit().Name"
                          v-model="person.Name"
                          class="mb-3"
                          :class="{
                            'inp-invalid': $h.isBlank(person.Name) && isRequired(person),
                          }"
                        />
                      </div>

                      <div class="col-md-6">
                        <p class="form-control-label">
                          {{ $t.translate("FRM_POSITION") }}
                        </p>

                        <el-input
                          show-word-limit
                          :maxlength="$h.getCharLimit().Position"
                          v-model="person.Position"
                          class="mb-3"
                          :class="{
                            'inp-invalid':
                              $h.isBlank(person.Position) && isRequired(person),
                          }"
                        />
                      </div>

                      <div class="col-md-6">
                        <p class="form-control-label">
                          {{ $t.translate("FRM_MOBILE_NUMBER") }}
                        </p>
                        <el-input
                          show-word-limit
                          :maxlength="$h.getCharLimit().PhoneNumber"
                          v-model="person.MobileNumber"
                          class="mb-3"
                          :class="{
                            'inp-invalid':
                              ($h.isBlank(person.MobileNumber) ||
                                !$h.isNumeric(person.MobileNumber)) &&
                              isRequired(person),
                          }"
                        >
                          <template #prepend>
                            <CountryCode v-model="person.MobileCode" />
                          </template>
                        </el-input>
                      </div>

                      <!-- only one general Fax for the company, no need for each person -->
                      <!--<div class="col-md-6">
                      <p class="form-control-label">{{ $t.translate("FRM_FAX") }}</p>
                      <base-input
                        v-model="person.Fax"
                        form-classes="mb-3"
                      />
                    </div>-->

                      <div class="col-md-6">
                        <p class="form-control-label">{{ $t.translate("FRM_EMAIL") }}</p>

                        <el-input
                          show-word-limit
                          :maxlength="$h.getCharLimit().Email"
                          v-model="person.Email"
                          class="mb-3"
                          :class="{
                            'inp-invalid':
                              ($h.isBlank(person.Email) && isRequired(person)) ||
                              (!$h.isBlank(person.Email) &&
                                !$h.validateEmail(person.Email)),
                          }"
                        />
                      </div>
                    </template>

                    <div class="col-md-12">
                      <el-button size="small" @click="clear(person)">{{
                        $t.translate("BTN_CLEAR")
                      }}</el-button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentMode == 'documents'" class="shadow bg-white p-around mt-3">
        <div class="form-cont">
          <div class="sec-div">
            <div class="row align-items-center">
              <div class="col-md-11">
                <p class="form-control-label mb-3">
                  {{ $t.translate("TTL_WE_ENCLOSE_FOLLOWING_DOCU") }}:
                </p>
              </div>
            </div>

            <div v-for="(item, index) in DocuTypes" :key="'doc-type-' + item.value">
              <div
                class="d-flex align-items-center mb-2"
                :class="{
                  'space-between': $h.isSmall(),
                }"
              >
                <p class="mb-0 ml-2 mr-3">
                  {{ index + 1 }}. {{ $t.translate(item.label) }}
                </p>

                <el-upload
                  :show-file-list="false"
                  :action="
                    $axios.formulateUrl(
                      `/media/upload/tmp?general=1${
                        formData.ApplicationKey
                          ? `&ApplicationKey=${formData.ApplicationKey}`
                          : ``
                      }`
                    )
                  "
                  :headers="$axios.getHeaders()"
                  :on-error="$axios.uploadError"
                  :on-progress="$axios.uploadProgress"
                  :on-success="
                    (response, file, fileList) =>
                      sucUploadDocu(response, file, fileList, item, $axios.doneUpload)
                  "
                  :accept="$h.getGeneralFileTypes()"
                >
                  <el-button
                    size="small"
                    class="c-pointer"
                    :class="{ 'inp-invalid': item?.compulsory && !ifFound(item.value) }"
                    >{{ $t.translate("BTN_UPLOAD") }}</el-button
                  >
                </el-upload>
              </div>
            </div>

            <div class="mx-4 mb-4 pre-line">
              <small>{{ $t.translate("LBL_UPLOAD_FILE_LIMIT") }}</small
              ><br />
              <small>{{
                $t.swapValues(
                  {
                    SPECIAL_CHARS: $h.getSpecialChars(),
                  },
                  $t.translate("LBL_UPLOAD_FILE_NAME_LIMIT")
                )
              }}</small>
            </div>

            <div
              v-if="formData.formDocuments.length > 0"
              class="fields-list border p-around"
            >
              <div class="row d-none d-md-flex border-bottom border-gray pb-2">
                <b class="col-md-1">#</b>
                <b class="col-md-4">{{ $t.translate("FRM_DOCUMENT_TYPE") }}</b>
                <b class="col-md-3">{{ $t.translate("FRM_FILE_NAME") }}</b>
                <b class="col-md-3">{{ $t.translate("LBL_UPLOAD_DATE_TIME") }}</b>
                <b class="col-md-1 pl-md-0 pr-md-0">{{ $t.translate("TH_ACTION") }}</b>
              </div>
              <div
                v-for="(doc, docInd) in formData.formDocuments"
                :key="'form-doc-' + docInd"
                class="row mt-2 border-bottom border-gray align-items-center"
              >
                <div class="col-md-1">
                  <p class="mb-2"><span class="d-md-none">#</span>{{ docInd + 1 }}</p>
                </div>
                <div class="col-md-4">
                  <b class="d-md-none mr-2">{{ $t.translate("FRM_DOCUMENT_TYPE") }}:</b>
                  <div class="d-flex align-items-center mb-2">
                    <p class="mb-0 field-val">
                      {{ $t.translate($h.mapLabel(doc.DocuType, DocuTypes)) }}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <b class="d-md-none mr-2">{{ $t.translate("FRM_FILE_NAME") }}:</b>
                  <p class="mb-2 field-val">{{ doc.FileName }}</p>
                </div>
                <div class="col-md-3">
                  <b class="d-md-none mr-2"
                    >{{ $t.translate("LBL_UPLOAD_DATE_TIME") }}:</b
                  >
                  <p class="mb-2 field-val">{{ $h.formatDateTime(doc.UploadedAt) }}</p>
                </div>
                <div
                  class="col-md-1 d-flex align-items-center pl-md-0 pr-md-0 mt-1 mt-md-0"
                >
                  <a
                    class="el-button el-button--text"
                    target="_blank"
                    :href="
                      $axios.formulateUrl('/export/download?Token=' + doc.DownloadToken)
                    "
                    ><i class="c-pointer fas fa-eye"></i
                  ></a>

                  <el-popconfirm
                    :confirmButtonText="$t.translate('LBL_YES')"
                    :cancelButtonText="$t.translate('LBL_NO')"
                    icon="el-icon-info"
                    iconColor="red"
                    :title="$t.translate('MSG_WARNING_CONFIRM_DELETE')"
                    @confirm="formData.formDocuments.splice(docInd, 1)"
                  >
                    <template #reference>
                      <el-button type="text"><i class="fas fa-trash"></i></el-button>
                    </template>
                  </el-popconfirm>
                </div>
              </div>
            </div>

            <p class="mt-4">
              {{ $t.translate("LBL_SUP_DOCU_AGREE_PRE") }}&nbsp;<a
                target="_blank"
                class="highlight"
                :href="'/privacy'"
                >{{ $t.translate("LBL_SUP_DOCU_AGREE_HIGHLIGHT") }}</a
              >.&nbsp;{{ $t.translate("LBL_SUP_DOCU_AGREE_AFTER") }}
            </p>
            <el-checkbox v-model="formData.notReceiveInfo" class="w-100">
              {{ $t.translate("LBL_TICK_IF_DONT_RECEIVE_INFO") }}
            </el-checkbox>
          </div>
        </div>
      </div>

      <div v-if="currentMode == 'checklist'" class="shadow bg-white p-around mt-3">
        <div class="form-cont">
          <div class="sec-div row" v-loading="iframeLoading">
            <div class="col-md-12">
              <el-alert
                v-if="!formData.MachformEntryKey"
                class="mb-4"
                :title="$t.translate('MSG_AC_PLS_PRESS_SAVE_BTN')"
                type="info"
                show-icon
                :closable="false"
              ></el-alert>

              <el-alert
                class="mb-4"
                v-if="formData.MachformEntryKey"
                :title="$t.translate('MSG_AC_SAVED_PLS_PRESS_BTN')"
                type="success"
                show-icon
                :closable="false"
              ></el-alert>

              <el-alert
                class="mb-4"
                v-if="formData.ShowMachformError"
                :title="$t.translate('ERR_CANT_RETRIEVE_MACHFORM_DATA')"
                type="error"
                show-icon
                :closable="false"
              ></el-alert>

              <iframe
                id="mf_iframe"
                :onload="() => (iframeLoading = false)"
                allowTransparency="true"
                scrolling="no"
                frameborder="0"
                style="width: 100%; border: none"
                :src="getAssessmentLink"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentMode == 'declaration'" class="shadow bg-white p-around mt-3">
        <div class="form-cont">
          <p class="sec-head">{{ $t.translate("TTL_SELF_DECLARATION") }}</p>

          <div class="sec-div row">
            <div class="col-md-12">
              <section v-html="SelfDeclarationData.content"></section>

              <el-checkbox v-model="formData.agreeDecla" class="mt-2">
                {{ $t.translate("LBL_AGREE_SELF_DECLAR") }}
              </el-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentMode == 'terms'" class="shadow bg-white p-around mt-3">
        <div class="form-cont">
          <p class="sec-head" style="text-transform: none">
            {{ $t.translate("TTL_TERMS_CONDITION") }}
          </p>

          <div class="sec-div row">
            <div class="col-md-12">
              <section v-html="TermsData.content"></section>

              <el-checkbox v-model="formData.agreeTerms" class="mt-3">
                {{ $t.translate("LBL_AGREE_APPLI_TERMS_CONDI") }}
              </el-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentMode == 'preview'" class="shadow bg-white p-around mt-3">
        <div class="form-cont">
          <div class="sec-div row">
            <div class="col-md-12 shadow bg-white p-around table-responsive pt-0">
              <div class="form-cont px-0">
                <div class="d-flex justify-content-between">
                  <p class="sec-head td-px mb-2">
                    {{ $t.translate("TTL_PROPOSAL") }}
                  </p>
                </div>
                <table class="form-table append-table mb-2">
                  <tr class="form-record">
                    <td class="sm-no-pb">{{ $t.translate("FRM_TYPE_OF_SERVICE") }}:</td>
                    <td class="text-md-right">
                      <b
                        >{{ $h.formatName(SelectedService, "Name")
                        }}<span v-if="$h.formatName(SelectedService, 'NameExtra', true)">
                          - {{ $h.formatName(SelectedService, "NameExtra") }}</span
                        ></b
                      >
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">{{ $t.translate("FRM_PROPOSAL_QUOTA_NO") }}:</td>
                    <td class="text-md-right">
                      <b>{{ formData.ProposalNo || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_PROPOSAL_QUOTA_BY_SYSTEM") }}:
                    </td>
                    <td class="text-md-right">
                      <b
                        ><a
                          v-if="formData?.ProposalFile?.File"
                          class="c-pointer"
                          target="_blank"
                          :href="
                            $axios.formulateUrl(
                              '/export/download?Token=' +
                                formData?.ProposalFile?.DownloadToken
                            )
                          "
                          >{{ formData?.ProposalFile?.File }}</a
                        ><span v-else>{{ "N/A" }}</span></b
                      >
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">{{ $t.translate("FRM_APPLICATION_FEE") }}:</td>
                    <td class="text-md-right">
                      <b>{{
                        $h.formatPrice(
                          formData.ApplicationFee,
                          $h.mapLabel(formData.Currency, CurrencyOptions)
                        )
                      }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">{{ $t.translate("FRM_ASSESSMENT_FEE") }}:</td>
                    <td class="text-md-right">
                      <b>{{
                        $h.formatPrice(
                          formData.AssessmentFee,
                          $h.mapLabel(formData.Currency, CurrencyOptions)
                        )
                      }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_TOTAL_REGISTRATION_FEE") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{
                        $h.formatPrice(
                          TotalRegisFee,
                          $h.mapLabel(formData.Currency, CurrencyOptions)
                        )
                      }}</b>
                    </td>
                  </tr>
                </table>

                <template v-if="BusinessStaff?.Email">
                  <p class="td-px mb-2">
                    <b>{{ $t.translate("FRM_REP_BUSIN_STAFF") }}</b>
                  </p>
                  <table class="form-table append-table mb-2">
                    <tr class="form-record">
                      <td class="sm-no-pb">{{ $t.translate("FRM_NAME") }}:</td>
                      <td class="text-md-right">
                        <b>{{ $h.formatName(BusinessStaff, "DisplayName") }}</b>
                      </td>
                    </tr>
                    <tr class="form-record">
                      <td class="sm-no-pb">{{ $t.translate("FRM_POSITION") }}:</td>
                      <td class="text-md-right">
                        <b>{{ BusinessStaff.Position || "N/A" }}</b>
                      </td>
                    </tr>
                    <tr class="form-record">
                      <td class="sm-no-pb">{{ $t.translate("FRM_TEL") }}:</td>
                      <td class="text-md-right">
                        <b
                          >{{ BusinessStaff.MobileCode }}
                          {{ BusinessStaff.MobileNumber }}</b
                        >
                      </td>
                    </tr>
                    <tr class="form-record">
                      <td class="sm-no-pb">{{ $t.translate("FRM_EMAIL") }}:</td>
                      <td class="text-md-right">
                        <b>{{ BusinessStaff.Email || "N/A" }}</b>
                      </td>
                    </tr>
                  </table>
                </template>

                <p class="sec-head td-px mb-2 mt-4">
                  {{ $t.translate("TTL_APPLICANT_INFO") }}
                </p>
                <table class="form-table append-table mb-2">
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_COMPANY_NAME_SAME_BUSINESS_REG") }}
                      {{ $t.translate("LANG_EN") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.Company || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_COMPANY_NAME_SAME_BUSINESS_REG") }}
                      {{ $t.translate("LANG_CHT") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.CompanyCHT || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_COMPANY_NAME_SAME_BUSINESS_REG") }}
                      {{ $t.translate("LANG_CHS") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.CompanyCHS || "N/A" }}</b>
                    </td>
                  </tr>

                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_BRANCH_IFANY") }}
                      {{ $t.translate("LANG_EN") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.BranchName || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_BRANCH_IFANY") }}
                      {{ $t.translate("LANG_CHT") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.BranchNameCHT || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_BRANCH_IFANY") }}
                      {{ $t.translate("LANG_CHS") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.BranchNameCHS || "N/A" }}</b>
                    </td>
                  </tr>

                  <tr class="form-record">
                    <td class="sm-no-pb">{{ $t.translate("FRM_REGION") }}:</td>
                    <td class="text-md-right">
                      <b>{{
                        RegionList.find((it) => it.Value == formData.Region)?.Label ||
                        "N/A"
                      }}</b>
                    </td>
                  </tr>

                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("LBL_ORGANIZATION_ADDRESS") }}
                      {{ $t.translate("LANG_EN") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.Address || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("LBL_ORGANIZATION_ADDRESS") }}
                      {{ $t.translate("LANG_CHT") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.AddressCHT || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("LBL_ORGANIZATION_ADDRESS") }}
                      {{ $t.translate("LANG_CHS") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.AddressCHS || "N/A" }}</b>
                    </td>
                  </tr>

                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_CORRESPONDING_ADDRESS_IF_DIFFERENT") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.CorrespondingAddress || "N/A" }}</b>
                    </td>
                  </tr>

                  <tr class="form-record">
                    <td class="sm-no-pb">{{ $t.translate("FRM_INDUSTRY") }}:</td>
                    <td class="text-md-right">
                      <b>{{
                        $h.formatName(
                          IndustryList.find(
                            (it) => it.IndustryId == formData.IndustryId
                          ) || {},
                          "Name"
                        ) || "N/A"
                      }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">{{ $t.translate("FRM_GENERAL_TEL_LINE") }}:</td>
                    <td class="text-md-right">
                      <b>{{ formData.MobileCode }} {{ formData.MobileNumber }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">{{ $t.translate("FRM_FAX") }}:</td>
                    <td class="text-md-right">
                      <b>{{ formData.Fax || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">{{ $t.translate("FRM_GENERAL_EMAIL") }}:</td>
                    <td class="text-md-right">
                      <b>{{ formData.Email || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_WEBSITE") }} ({{
                        $t.translate("LBL_IF_ANY")
                      }}):
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.Website || "N/A" }}</b>
                    </td>
                  </tr>

                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_SCOPE") }} {{ $t.translate("LANG_EN") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.Scope || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_SCOPE") }} {{ $t.translate("LANG_CHT") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.ScopeCHT || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_SCOPE") }} {{ $t.translate("LANG_CHS") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.ScopeCHS || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_SCOPE_CATG") }}
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.ScopeCategory || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_SITE_ADDR_IFANY") }}
                      {{ $t.translate("LANG_EN") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.ScopeAddress || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_SITE_ADDR_IFANY") }}
                      {{ $t.translate("LANG_CHT") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.ScopeAddressCHT || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_SITE_ADDR_IFANY") }}
                      {{ $t.translate("LANG_CHS") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{ formData.ScopeAddressCHS || "N/A" }}</b>
                    </td>
                  </tr>
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("FRM_LANG_PREFER_FOR_ASM") }}:
                    </td>
                    <td class="text-md-right">
                      <b>{{
                        $h.mapLabel(formData.PreferredAssessmentLang, LangList) || "N/A"
                      }}</b>
                    </td>
                  </tr>
                </table>

                <p class="sec-head td-px mb-2 mt-4">
                  {{ $t.translate("TTL_CONTACT_INFO") }}
                </p>
                <table class="form-table append-table mb-2">
                  <template v-for="(person, index) in getContactInfo" :key="index">
                    <tr class="form-record">
                      <td class="sm-no-pb">
                        <b>{{ $t.translate(person.Label) }}</b>
                      </td>
                      <td class="text-md-right"></td>
                    </tr>
                    <tr class="form-record">
                      <td class="sm-no-pb">{{ $t.translate("FRM_NAME") }}</td>
                      <td class="text-md-right">
                        <b
                          >{{ $h.mapLabel(person.PersonTitle, PersonTitleOpts) }}
                          {{ person.Name || "N/A" }}</b
                        >
                      </td>
                    </tr>
                    <tr class="form-record">
                      <td class="sm-no-pb">{{ $t.translate("FRM_POSITION") }}</td>
                      <td class="text-md-right">
                        <b>{{ person.Position || "N/A" }}</b>
                      </td>
                    </tr>
                    <tr class="form-record">
                      <td class="sm-no-pb">{{ $t.translate("FRM_MOBILE_NUMBER") }}</td>
                      <td class="text-md-right">
                        <b>{{
                          person.MobileNumber
                            ? person.MobileCode + " " + person.MobileNumber
                            : "N/A"
                        }}</b>
                      </td>
                    </tr>
                    <!-- <tr class="form-record">
                      <td class="sm-no-pb">{{ $t.translate("FRM_FAX") }}</td>
                      <td class="text-md-right">
                        <b>{{ person.Fax || "N/A" }}</b>
                      </td>
                    </tr> -->
                    <tr class="form-record">
                      <td class="sm-no-pb">{{ $t.translate("FRM_EMAIL") }}</td>
                      <td class="text-md-right">
                        <b>{{ person.Email || "N/A" }}</b>
                      </td>
                    </tr>
                  </template>
                </table>

                <div
                  class="d-flex justify-content-between align-items-center mb-2 mt-4 td-px"
                >
                  <p class="sec-head mb-0">
                    {{ $t.translate("TTL_DOCUMENTS") }}
                  </p>
                </div>

                <div class="sec-div fields-list border p-around mb-3 td-mx">
                  <div class="row d-none d-md-flex border-bottom border-gray pb-2">
                    <b class="col-md-1">#</b>
                    <b class="col-md-4">{{ $t.translate("FRM_DOCUMENT_TYPE") }}</b>
                    <b class="col-md-3">{{ $t.translate("FRM_FILE_NAME") }}</b>
                    <b class="col-md-3">{{ $t.translate("LBL_UPLOAD_DATE_TIME") }}</b>
                    <b class="col-md-1 pl-md-0 pr-md-0">{{
                      $t.translate("TH_ACTION")
                    }}</b>
                  </div>

                  <div
                    class="row mt-2 align-items-center"
                    v-if="!formData?.formDocuments?.length"
                  >
                    <div class="col-md-12">N/A</div>
                  </div>

                  <div
                    v-for="(doc, docInd) in formData.formDocuments"
                    :key="'form-doc-' + docInd"
                    class="row mt-2 border-bottom border-gray align-items-center"
                  >
                    <div class="col-md-1">
                      <p class="mb-2"><span class="d-md-none">#</span>{{ docInd + 1 }}</p>
                    </div>
                    <div class="col-md-4">
                      <b class="d-md-none mr-2"
                        >{{ $t.translate("FRM_DOCUMENT_TYPE") }}:</b
                      >
                      <p class="mb-2 field-val">
                        {{ $t.translate($h.mapLabel(doc.DocuType, DocuTypes)) }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <b class="d-md-none mr-2">{{ $t.translate("FRM_FILE_NAME") }}:</b>
                      <p class="mb-2 field-val">{{ doc.FileName }}</p>
                    </div>
                    <div class="col-md-3">
                      <b class="d-md-none mr-2"
                        >{{ $t.translate("LBL_UPLOAD_DATE_TIME") }}:</b
                      >
                      <p class="mb-2 field-val">
                        {{ $h.formatDateTime(doc.UploadedAt) }}
                      </p>
                    </div>
                    <div
                      class="col-md-1 d-flex align-items-center pl-md-0 pr-md-0 mt-1 mt-md-0"
                    >
                      <a
                        class="el-button el-button--text"
                        target="_blank"
                        :href="
                          $axios.formulateUrl(
                            '/export/download?Token=' + doc.DownloadToken
                          )
                        "
                      >
                        <i class="c-pointer fas fa-eye"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <table class="form-table append-table">
                  <tr class="form-record">
                    <td class="sm-no-pb">
                      {{ $t.translate("LBL_TICK_IF_DONT_RECEIVE_INFO") }}
                    </td>
                    <td class="text-md-right">
                      {{
                        formData.notReceiveInfo
                          ? $t.translate("LBL_YES")
                          : $t.translate("LBL_NO")
                      }}
                    </td>
                  </tr>
                </table>
              </div>

              <div
                class="d-flex justify-content-between align-items-center mb-2 mt-4 td-px"
              >
                <p class="sec-head mb-0">
                  {{ $t.translate("TTL_ASSESSMENT_CHECKLIST") }}
                </p>
              </div>

              <div class="col-md-12 mb-4">
                <AssessmentChecklist
                  v-if="SelectedService?.MachformId"
                  :data="{
                    MachformId: SelectedService.MachformId,
                    MachformEntryKey: formData.MachformEntryKey,
                  }"
                />
              </div>

              <table class="form-table append-table">
                <tr class="form-record">
                  <td class="sm-no-pb">
                    {{ $t.translate("LBL_AGREE_SELF_DECLAR_ALT") }}
                  </td>
                  <td class="text-md-right">
                    {{
                      formData.agreeDecla
                        ? $t.translate("LBL_YES")
                        : $t.translate("LBL_NO")
                    }}
                  </td>
                </tr>
              </table>

              <table class="form-table append-table">
                <tr class="form-record">
                  <td class="sm-no-pb">
                    {{ $t.translate("LBL_AGREE_APPLI_TERMS_CONDI_ALT") }}
                  </td>
                  <td class="text-md-right">
                    {{
                      formData.agreeTerms
                        ? $t.translate("LBL_YES")
                        : $t.translate("LBL_NO")
                    }}
                  </td>
                </tr>
              </table>

              <div class="col-md-6 td-px mt-4" v-loading="savingSignature">
                <p class="sec-head mb-2">
                  <b>{{ $t.translate("FRM_ACCEPTED_CONFIRMED_BY") }}</b>
                </p>

                <el-radio-group
                  v-model="formData.SignedByTitle"
                  :class="{
                    'inp-invalid': $h.isBlank(formData.SignedByTitle),
                  }"
                >
                  <el-radio
                    v-for="item in PersonTitleOpts"
                    :key="item.value"
                    :label="item.value"
                  >
                    {{ $t.translate(item.label) }}
                  </el-radio>
                </el-radio-group>

                <el-input
                  show-word-limit
                  :maxlength="$h.getCharLimit().Name"
                  v-model="formData.SignedBy"
                  type="text"
                  class="on-behalf-input"
                  :class="{
                    'inp-invalid': $h.isBlank(formData.SignedBy),
                    'mb-3': !$h.isSmall(),
                    'mb-2': $h.isSmall(),
                    'full-width': $h.isSmall(),
                  }"
                >
                  <template v-if="!$h.isSmall()" #append>{{
                    $t
                      .translate("LBL_ON_BEHALF_OF_COMPANY")
                      .replace("{COMPANY}", $h.formatOrgBranch(formData))
                  }}</template>
                </el-input>

                <div class="mb-3" v-if="$h.isSmall()">
                  {{
                    $t
                      .translate("LBL_ON_BEHALF_OF_COMPANY")
                      .replace("{COMPANY}", $h.formatOrgBranch(formData))
                  }}
                </div>

                <el-tabs
                  type="border-card"
                  v-model="formData.SignatureType"
                  @tab-click="handleTabChange"
                >
                  <el-tab-pane :label="$t.translate('LBL_SIGN')" name="SIGN">
                    <el-image
                      class="border"
                      v-if="formData.Signature"
                      style="width: 100%; height: 220px"
                      fit="contain"
                      :src="$axios.formulateUrl(`/image/view/tmp/${formData.Signature}`)"
                    ></el-image>

                    <VueSignaturePad
                      v-if="!formData.Signature && IsShowSignature"
                      ref="applicantSign"
                      height="220px"
                      class="border w-100 mt-2"
                    />

                    <div v-if="!formData.Signature">
                      <el-button type="primary" class="mt-3 mb-2" @click="save">{{
                        $t.translate("BTN_SAVE")
                      }}</el-button>
                      <el-button type="default" class="mt-3 mb-2" @click="undo">{{
                        $t.translate("BTN_UNDO")
                      }}</el-button>
                    </div>
                    <template v-else>
                      <div>
                        <el-button
                          type="primary"
                          class="mt-3 mb-2"
                          @click="changeSignature"
                          >{{ $t.translate("BTN_CHANGE") }}</el-button
                        >
                      </div>

                      <small
                        style="display: block"
                        v-if="formData.SignedByTitle && formData.SignedBy"
                        >{{
                          $t
                            .translate("LBL_SIGNED_BY_ON_BEHALF_OF_COMPANY")
                            .replace(
                              "{SIGNED}",
                              `${$h.mapLabel(formData.SignedByTitle, PersonTitleOpts)}. ${
                                formData.SignedBy
                              }`
                            )
                            .replace(
                              "{SIGNED_CH}",
                              `${formData.SignedBy}${$h.mapLabel(
                                formData.SignedByTitle,
                                PersonTitleOpts
                              )}`
                            )
                            .replace("{COMPANY}", $h.formatOrgBranch(formData))
                        }}</small
                      >

                      <small
                        >{{ $t.translate("FRM_SIGNED_DATE") }}:
                        {{ $h.formatDateTime(formData.SignDate) }}</small
                      >
                    </template>
                  </el-tab-pane>
                  <el-tab-pane :label="$t.translate('LBL_UPLOAD')" name="UPLOAD">
                    <div v-if="formData?.Signature?.File" class="mr-3">
                      <a
                        class="c-pointer"
                        target="_blank"
                        :href="
                          $axios.formulateUrl(
                            '/export/download?Token=' + formData?.Signature?.DownloadToken
                          )
                        "
                        style="word-wrap: break-word; margin-bottom: 10px; display: block"
                        >{{ formData.Signature.File }}</a
                      >
                    </div>

                    <el-upload
                      :show-file-list="false"
                      :action="
                        $axios.formulateUrl(
                          `/media/upload/tmp?general=1${
                            formData.ApplicationKey
                              ? `&ApplicationKey=${formData.ApplicationKey}`
                              : ``
                          }`
                        )
                      "
                      :headers="$axios.getHeaders()"
                      :on-error="$axios.uploadError"
                      :on-progress="$axios.uploadProgress"
                      :on-success="
                        (res, file) => sucUploadSignature(res, file, $axios.doneUpload)
                      "
                      :accept="$h.getGeneralFileTypes()"
                    >
                      <el-button type="primary" class="mt-3 mb-2">{{
                        $t.translate("BTN_UPLOAD")
                      }}</el-button>
                    </el-upload>
                    <div v-if="formData.Signature">
                      <small
                        >{{ $t.translate("FRM_SIGNED_DATE") }}:
                        {{ $h.formatDateTime(formData.SignDate) }}</small
                      >
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="nav-bottom">
    <div></div>
    <div class="d-flex">
      <el-button
        v-if="currentMode != 'terms'"
        type="default"
        class="sm-btn"
        @click="triggerSave"
      >
        {{ $t.translate("BTN_SAVE") }}
      </el-button>

      <el-button v-if="currentStep > 0" type="default" class="sm-btn" @click="backPre">
        {{ $t.translate("BTN_PREV") }}
      </el-button>

      <el-button
        v-if="currentMode != 'preview'"
        :disabled="
          (currentMode == 'checklist' && !formData.IsMachformComplete) ||
          (currentMode == 'declaration' && !formData.agreeDecla) ||
          (currentMode == 'terms' && !formData.agreeTerms)
        "
        type="primary"
        class="sm-btn ml-2 ml-md-4"
        @click="toNext"
      >
        {{ $t.translate("BTN_NEXT") }}
      </el-button>

      <el-button v-else type="primary" class="sm-btn ml-2 ml-md-4" @click="toSubmit">
        {{ $t.translate("BTN_SUBMIT") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";

import AssessmentChecklist from "@/components/AssessmentChecklist";
import CountryCode from "@/components/CountryCode";

import { useRoute, onBeforeRouteLeave } from "vue-router";

import { onMounted, reactive, ref, computed, watch } from "vue";
import { helpers } from "@/utils/helpers";
import { formulateUrl, get, post, dataOnly, isValidResponse } from "@/utils/axios";
import { router } from "@/utils/router";
import { store } from "@/store";
import { translation } from "@/utils/translation";
import { ElMessageBox } from "element-plus";
import { useMq } from "vue3-mq";

import { emitter } from "@/utils/emitter";

export default {
  components: {
    CountryCode,
    AssessmentChecklist,
  },
  setup() {
    let { RegionList } = store.getters["config/getData"]?.envConf?.organization;
    let skipLeaveChecking = false;

    const ApplicationReferenceKey = helpers.generateRandomCharacters(8);
    const loaded = ref(false);
    const mq = useMq();
    const route = useRoute();
    const applicantSign = ref("applicantSign");
    let IndustryList = ref([]);

    let InitialData = ref(null);
    let BusinessStaff = ref(null);
    let ServicesList = ref([]);
    let iframSaveResolve = null;
    let iframeSkip = false;

    let IsRenewal = computed(() => {
      return route.path.indexOf("/application/renew") > -1 ? true : false;
    });

    let Steps = [
      {
        Label: "TTL_TERMS_CONDITION",
        Mode: "terms",
      },
      {
        Label: "TTL_PROPOSAL",
        Mode: "proposal",
      },
      {
        Label: "TTL_APPLICANT_INFO",
        Mode: "applicant",
      },
      {
        Label: "TTL_CONTACT_INFO",
        Mode: "contact",
      },
      {
        Label: "TTL_DOCUMENTS",
        Mode: "documents",
      },
      {
        Label: "TTL_ASSESSMENT_CHECKLIST",
        Mode: "checklist",
      },
      {
        Label: "TTL_SELF_DECLARATION",
        Mode: "declaration",
      },
      {
        Label: "TTL_PREVIEW",
        Mode: "preview",
      },
    ];

    let {
      CurrencyOpts,
      PersonTitleOpts,
      DocuTypes,
      BoundaryProgram,
      ContactTypeList,
      ContactTypeFields,
      ContactRequiredList,
      LangList,
      ScopeRequirement,
      AccessmentTypeOpts,
    } = store.getters["config/getData"]?.envConf;

    let OrganizationData = ref({});
    let SelfDeclarationData = ref({});
    let TermsData = ref({});

    let currentStep = ref(0);

    let currentMode = computed(() => {
      let found = _.find(Steps, (r, index) => index == currentStep.value);
      return found?.Mode;
    });

    let stepStatus = ref({});
    let formData = reactive({
      IsScopeDisabled: false,
      IsScopeSiteAddressDisabled: false,
      IsOrgAddressDisabled: false,
      AssessmentTypeCheck: "INITIAL",

      ShowMachformError: false,
      AssessmentType: "",
      ReviewToken: "",

      OrgNameEN: "",
      OrgNameCHT: "",
      OrgNameCHS: "",

      Company: "",
      CompanyCHT: "",
      CompanyCHS: "",
      BranchName: "",
      BranchNameCHT: "",
      BranchNameCHS: "",
      Address: "",
      AddressCHT: "",
      AddressCHS: "",
      Region: "",
      IndustryId: "",
      Currency: "",
      AssessmentServiceKey: "",
      ApplicationKey: "",
      MachformEntryKey: "",
      IsMachformComplete: 0,
      ApplicationFee: 0,
      AssessmentFee: 0,
      SignatureType: "SIGN",
      Signature: "",
      SignDate: "",
      SignedBy: "",
      SignedByTitle: "",

      CorrespondingAddress: "",
      MobileCode: "",
      MobileNumber: "",
      Fax: "",
      Email: "",
      Website: "",

      //RegisBoundary: null,
      //regisInfo: {},
      // based on client comment, RegisBoundary and regisInfo should be replaced by below scope fields
      Scope: "",
      ScopeCHT: "",
      ScopeCHS: "",
      ScopeCategory: "",
      ScopeAddress: "",
      ScopeAddressCHT: "",
      ScopeAddressCHS: "",
      PreferredAssessmentLang: "",

      notReceiveInfo: false,
      agreeDecla: false,
      agreeTerms: false,

      contactInfo: [],
      formDocuments: [],

      ProposalNo: "",
      ProposalFile: "",

      LastStepValue: null,
    });

    let CurrencyOptions = computed(() => {
      if (!formData.AssessmentServiceKey) return [];

      let item = _.find(ServicesList.value, [
        "AssessmentServiceKey",
        formData.AssessmentServiceKey,
      ]);
      return _.filter(CurrencyOpts, (r) => {
        return item?.Currency?.indexOf(r.value) > -1;
      });
    });
    let SelectedService = computed(() => {
      if (!formData.AssessmentServiceKey) return {};

      let item = _.find(ServicesList.value, [
        "AssessmentServiceKey",
        formData.AssessmentServiceKey,
      ]);
      return item || {};
    });

    const loadDefaultValues = async (CertificateKey, UserKey, IsSubmitted = false) => {
      let DefaultData = {};
      let UserData = {};

      if (CertificateKey) {
        UserData = (await get(`/user/view`, { UserKey }))?.User;
        let res = await get("/application/certificate/view", {
          GetInitial: true,
          CertificateKey,
        });
        DefaultData = res?.Certificate?.InitialAssessment;
      } else {
        UserData = await get(`/application/user`, {
          ApplicationKey: route?.query?.key || "",
        });
      }

      formData.IsLocked = 1;

      if (!UserData?.IsBranchOrgLocked && formData?.AssessmentTypeCheck == "INITIAL") {
        formData.IsLocked = 0;
      }

      if (helpers.hasRoles(["ADMIN", "SUB_ADMIN"])) {
        formData.IsLocked = 0; //remove lock if admin / sub admin
      }

      // if (
      //   formData?.AssessmentTypeCheck == "SPECIAL" &&
      //   formData?.ApplicationStatusCodeCheck != "CERTIFIED" &&
      //   formData?.ApplicationStatusCodeCheck != "COMPLETED"
      // ) {
      //   formData.IsLocked = 0;
      // }

      if (IsSubmitted) return;

      //lang select
      formData.PreferredAssessmentLang = LangList?.[0]?.value || "";

      //read only fields
      formData.Region =
        DefaultData?.Organization?.Region || UserData?.Organization?.Region;
      formData.IndustryId =
        DefaultData?.Organization?.IndustryId || UserData?.Organization?.IndustryId;

      formData.Company = UserData?.Organization?.OrgNameEN;
      formData.CompanyCHT = UserData?.Organization?.OrgNameCHT;
      formData.CompanyCHS = UserData?.Organization?.OrgNameCHS;

      formData.OrgNameEN = UserData?.Organization?.OrgNameEN;
      formData.OrgNameCHT = UserData?.Organization?.OrgNameCHT;
      formData.OrgNameCHS = UserData?.Organization?.OrgNameCHS;

      //dynamic fields
      formData.BranchName =
        DefaultData?.BranchName || UserData?.Organization?.BranchNameEN;
      formData.BranchNameCHT =
        DefaultData?.BranchNameCHT || UserData?.Organization?.BranchNameCHT;
      formData.BranchNameCHS =
        DefaultData?.BranchNameCHS || UserData?.Organization?.BranchNameCHS;

      formData.Address = DefaultData?.Address || UserData?.Organization?.OrgAddressEN;
      formData.AddressCHT =
        DefaultData?.AddressCHT || UserData?.Organization?.OrgAddressCHT;
      formData.AddressCHS =
        DefaultData?.AddressCHS || UserData?.Organization?.OrgAddressCHS;

      formData.ScopeCategory = DefaultData?.ScopeCategory || "";

      formData.CorrespondingAddress =
        DefaultData?.CorrespondingAddress ||
        helpers.formatName(UserData?.Organization, "OrgCorAddress", true);

      formData.MobileCode =
        DefaultData?.MobileCode || UserData?.Organization?.MobileCode || "";
      formData.MobileNumber =
        DefaultData?.MobileNumber || UserData?.Organization?.MobileNumber || "";

      formData.Fax = DefaultData?.Fax || UserData?.Organization?.Fax || "";
      formData.Email = DefaultData?.Email || UserData?.Organization?.Email || "";
      formData.Website = DefaultData?.Website || UserData?.Organization?.Website || "";

      formData.Scope = DefaultData?.Scope || "";
      formData.ScopeCHT = DefaultData?.ScopeCHT || "";
      formData.ScopeCHS = DefaultData?.ScopeCHS || "";

      formData.ScopeAddress = DefaultData?.ScopeAddress || "";
      formData.ScopeAddressCHS = DefaultData?.ScopeAddressCHS || "";
      formData.ScopeAddressCHT = DefaultData?.ScopeAddressCHT || "";

      if (DefaultData?.contactInfo?.length) {
        //assign contact info
        formData.contactInfo = DefaultData.contactInfo;
      }
    };

    const loadDefaultData = async () => {
      const UserData = await get(`/application/user`, {
        ApplicationKey: route?.query?.key || "",
      });
      BusinessStaff.value = UserData?.AssignedBusinessStaff || null;

      const industryData = await get(`/assessment/industry`);
      IndustryList.value = industryData?.IndustryList || [];

      let Application = null;

      if (route?.query?.key) {
        let res = await get("/application/view", { ApplicationKey: route.query.key });
        Application = res?.Application || null;

        if (!Application) {
          return router.goBack();
        }

        //replace
        if (Application?.User?.AssignedBusinessStaff) {
          BusinessStaff.value = Application.User.AssignedBusinessStaff;
        }

        let keys = Object.keys(formData);
        _.each(keys, (field) => {
          formData[field] =
            Application?.[field] ||
            (typeof Application?.[field] == "number" ||
            typeof Application?.[field] == "boolean"
              ? Application?.[field]
              : "");
        });

        if (helpers.hasRoles(["CLIENT", "SUB_CLIENT"]) && formData?.LastStepValue) {
          let index = _.findIndex(Steps, (r) => r.Mode == formData?.LastStepValue);
          if (index > -1) {
            currentStep.value = index;
          }
        }
        // changeAmount();

        formData.ApplicationStatusCodeCheck = Application?.ApplicationStatusCode;
        formData.AssessmentTypeCheck = Application?.AssessmentType;

        formData.IsScopeDisabled =
          ["RENEWAL", "SURVEILLANCE"].indexOf(Application.AssessmentType) > -1
            ? true
            : false;

        formData.IsScopeSiteAddressDisabled =
          ["RENEWAL", "SURVEILLANCE"].indexOf(Application.AssessmentType) > -1
            ? true
            : false;

        formData.IsOrgAddressDisabled =
          ["RENEWAL", "SURVEILLANCE"].indexOf(Application.AssessmentType) > -1
            ? true
            : false;

        if (formData.Signature && (!formData.SignedBy || !formData.SignedByTitle)) {
          formData.SignDate = "";
          formData.Signature = "";
        }

        if (formData.AssessmentServiceKey) {
          let found = _.find(ServicesList.value, [
            "AssessmentServiceKey",
            formData.AssessmentServiceKey,
          ]);

          if (!found) {
            let res = await get(
              `/assessment/list?page=1&size=1&AssessmentServiceKey=${formData.AssessmentServiceKey}`,
              {}
            );
            if (res?.data?.length) {
              ServicesList.value.push(res.data[0]);
            } else {
              skipLeaveChecking = true;
              helpers.showWarning({
                Code: "ERR_ASSESSMENT_SERVICE_NOT_FOUND",
                Message: "ERR_ASSESSMENT_SERVICE_NOT_FOUND",
              });
              router.goBack();
            }
          }

          await changeService(
            formData.AssessmentServiceKey,
            Application.IsSubmitted ? true : false
          );
        }

        await loadDefaultValues(
          Application.CertificateKey,
          Application.User.UserKey,
          Application.IsSubmitted ? true : false
        );
      } else {
        let found = _.find(
          ServicesList.value,
          (r) => r.AssessmentServiceKey == route?.query?.service
        );
        if (found) {
          await changeService(found.AssessmentServiceKey);
        } else if (ServicesList.value.length) {
          //client required not to default select
          //await changeService(ServicesList.value[0].AssessmentServiceKey);
        }

        await loadDefaultValues();
      }

      let fields = [
        "BranchNameEN",
        "BranchNameCHT",
        "BranchNameCHS",
        "OrgNameEN",
        "OrgNameCHT",
        "OrgNameCHS",
      ];

      if (Application) {
        OrganizationData.value = _.pick(Application?.User?.Organization, fields);
      } else {
        OrganizationData.value = _.pick(UserData?.Organization, fields);
      }

      SelfDeclarationData.value = await get("/cms/get/application-self-declaration.json");
      TermsData.value = await get("/cms/get/application-terms-and-conditions.json");
    };

    const loadContactList = async () => {
      if (formData.contactInfo.length) return;
      const UserData = await get(`/application/user`, {
        ApplicationKey: route?.query?.key || "",
      });

      let Members = UserData?.Organization?.Member || [];
      formData.contactInfo = _.map(ContactTypeList, (r) => {
        var fMember = _.find(Members, (it) => r.Type == it.Type);
        var cFields = _.assign({}, ContactTypeFields);
        if (fMember) {
          var keys = Object.keys(cFields);
          _.each(keys, (key) => {
            cFields[key] = fMember?.[key] || "";
          });
        }
        return {
          Type: r.Type,
          Label: r.Label,
          ...cFields,
        };
      });
    };

    const setMachformId = async (e, IsResume) => {
      let MachformEntryId = Number(
        e.data.replace(/.*mf_entry_id=(\d*(\.\d+)?)(?:&|$)/, "$1")
      );

      if (IsResume) {
        MachformEntryId = Number(
          e.data.replace(/.*mf_resume_entry_id=(\d*(\.\d+)?)(?:&|$)/, "$1")
        );
      }

      if (helpers.isBlank(MachformEntryId)) return;

      formData.ShowMachformError = false;

      if (formData?.ApplicationKey) {
        await post("/application/machform/update", {
          MachformEntryKey: MachformEntryId,
          ApplicationReferenceKey,
          ApplicationKey: formData?.ApplicationKey,
        });
      }

      formData.MachformEntryKey = MachformEntryId;
      if (!formData.IsMachformComplete && !IsResume) {
        formData.IsMachformComplete = 1;
      }

      //to reload
      document.getElementById("mf_iframe").src = getAssessmentLink?.value;

      if (
        MachformEntryId &&
        currentMode.value == "checklist" &&
        store.getters["page/getData"]?.isIframeProcessing
      ) {
        await store.dispatch("page/setData", { isIframeProcessing: false });
        await saveChanges(iframeSkip);
        iframSaveResolve();
      }
    };

    const checkData = async (e) => {
      if (
        e.data !== undefined &&
        e?.data?.type != "webpackClose" &&
        e?.data?.type != "webpackInvalid" &&
        e?.data?.type != "webpackWarnings"
      ) {
        if (e.data.indexOf("mf_iframe_height") > -1) {
          helpers.iFrameHeight(e, "mf_iframe");
        } else if (e.data.indexOf("mf_form_loaded") > -1) {
          helpers.scrollToTop("stepTopView");
        } else if (e.data.indexOf("mf_entry_id") > -1) {
          setTimeout(() => setMachformId(e), 1000);
        } else if (e.data.indexOf("mf_resume_entry_id") > -1) {
          setTimeout(() => setMachformId(e, true), 1000);
        } else if (e.data.indexOf("mf_form_updated") > -1) {
          formData.MachformLastUpdate = new Date().getTime();
        } else if (e.data.indexOf("mf_no_resume") > -1) {
          await store.dispatch("page/setData", { isIframeProcessing: false });
          helpers.showWarning({
            Code: "MSG_ASSESSMENT_CHECKLIST_IS_NOT_ABLE_TO_SAVE_AND_RESUME",
            Message: "MSG_ASSESSMENT_CHECKLIST_IS_NOT_ABLE_TO_SAVE_AND_RESUME",
          });
        }
      }
    };

    const getFormValue = () => {
      return { ...formData, LastStepValue: null };
    };

    const getInitialData = async () => {
      InitialData.value = JSON.stringify(getFormValue());
    };

    let confirmResolve = null;
    const showDialog = ref(false);

    const helpConfirmsave = () => {
      showDialog.value = true;
      return new Promise((resolve) => {
        confirmResolve = resolve;
      });
    };

    const dialogResponse = (response) => {
      showDialog.value = false;
      confirmResolve(response);
    };

    onBeforeRouteLeave(async (to, from) => {
      if (skipLeaveChecking) return true;

      let currentData = JSON.stringify(getFormValue());
      if (currentData != InitialData.value) {
        let resp = await helpConfirmsave();
        if (resp == "cancel") {
          return false;
        } else if (resp == "save") {
          if (formData.MachformLastUpdate && currentMode.value == "checklist") {
            await saveIncludingMachform(true);
            return true;
          } else {
            await saveChanges(true);
            return true;
          }
        } else if (resp == "discard") {
          return true;
        }
      } else {
        return true;
      }
    });

    onMounted(async () => {
      emitter.all.delete("iframe-message");
      emitter.on(`iframe-message`, checkData);

      let List = await get(`/assessment/list?page=1&size=9999&IsActive=1`, {});
      ServicesList.value = List?.data;

      //if add
      await loadDefaultData();

      await isFinished();
      await getInitialData();
      loaded.value = true;
    });

    const TotalRegisFee = computed(() => {
      return formData.ApplicationFee + formData.AssessmentFee;
    });

    /*const regisFieldChecked = (key) => {
      return formData.RegisBoundary == key;
    };

    const changeBoundary = (val) => {
      for (let key of Object.keys(formData.regisInfo)) {
        formData.regisInfo[key] = "";
      }
      if (val == "products") {
        let found = _.find(BoundaryProgram, (r) => r.value == val);
        if (found && found.radio) {
          formData.regisInfo[found.radio] = found.radioList[0].value;
        }
      }
    };*/

    const updateScope = (val, cc) => {
      let { rLimit, charLimit } = ScopeRequirement;
      let maxChar = charLimit[cc];
      let rows = helpers.autoTrimParagraph(val || "", rLimit, maxChar);
      formData["Scope" + (cc == "EN" ? "" : cc)] = rows.join("\n");
    };

    const changeAmount = () => {
      if (!formData.Currency) return;

      var item = _.find(ServicesList.value, [
        "AssessmentServiceKey",
        formData.AssessmentServiceKey,
      ]);

      var fee = _.find(item.AmountList, ["CurrencyCode", formData.Currency]);
      formData.ApplicationFee = fee?.ApplicationFee || 0;
      formData.AssessmentFee = fee?.AssessmentFee || 0;
    };

    const changeService = async (AssessmentServiceKey, IsSubmitted = false) => {
      var item = _.find(ServicesList.value, [
        "AssessmentServiceKey",
        AssessmentServiceKey,
      ]);

      formData.AssessmentServiceKey = item.AssessmentServiceKey;
      if (!formData.Currency) {
        formData.Currency = CurrencyOptions?.value?.[0]?.value || "";
      }

      if (!IsSubmitted) {
        changeAmount();
      }
    };

    const undo = () => {
      applicantSign.value.undoSignature();
    };

    const changeSignature = () => {
      formData.Signature = null;
      formData.SignDate = null;
      //applicantSign.value.clearSignature();
    };

    const savingSignature = ref(false);

    const save = async () => {
      const { isEmpty, data } = applicantSign.value.saveSignature();
      if (isEmpty) return;

      savingSignature.value = true;

      try {
        if (!formData.SignedByTitle || !formData.SignedBy) {
          throw "MSG_ERR_SIGNED_BY_NAME_AND_TITLE_REQUIRED";
        }

        const base64Response = await fetch(data);
        const blob = await base64Response.blob();

        let fd = new FormData();
        fd.append("file", blob, "signature.jpg");

        let res = await post("/media/upload/tmp", fd);
        if (!isValidResponse(res)) {
          throw "MSG_ERR_SIGNATURE_SAVE";
        }

        formData.Signature = res?.File;
        formData.SignDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
      } catch (err) {
        helpers.showWarning({
          Code: err,
          Message: err,
        });
      }

      savingSignature.value = false;
    };

    const sucUploadSignature = (response, file, cb) => {
      formData.Signature = {
        FileName: file.name,
        File: response?.data?.File,
        DownloadToken: response?.data?.DownloadToken,
      };
      formData.SignDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
      cb();
    };

    const sucUploadDocu = async (response, file, fileList, item, cb) => {
      formData.formDocuments.push({
        DocuType: item.value,
        FileName: file.name,
        UploadedAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        File: response?.data?.File,
        DownloadToken: response?.data?.DownloadToken,
      });
      cb();
      return true;
    };

    const sucUploadProposal = async (response, file, cb) => {
      formData.ProposalFile = {
        FileName: file.name,
        UploadedAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        File: response?.data?.File,
        DownloadToken: response?.data?.DownloadToken,
      };
      cb();
    };

    const redirectAfterSave = async (res) => {
      skipLeaveChecking = true;

      if (IsRenewal.value) {
        return router.replaceRoute("/generated_certs");
      }
      //if (res.ApplicationStatusCode == "INPROGRESS") {
      //  router.replaceRoute("/applications");
      //} else {
      router.replaceRoute("/viewform", { key: res.ApplicationKey });
      //}
    };

    const saveIncludingMachform = async (skip = false) => {
      //show loader first
      await store.dispatch("page/setData", { isIframeProcessing: true });
      //wait to resolve
      await new Promise((resolve, reject) => {
        iframSaveResolve = resolve; //pass the resolve
        iframeSkip = skip;

        //then send signal to iframe to submit to continue
        document
          .querySelector("#mf_iframe")
          .contentWindow.postMessage("mf_submit_continue", "*");
      });
    };

    const saveChanges = async (skip = false) => {
      let res = await post("/application/save", {
        ...formData,
      });
      if (!isValidResponse(res)) return;

      helpers.showMessage({
        Code: "MSG_SUCC_APPLICATION_SAVED",
        Message: "MSG_SUCC_APPLICATION_SAVED",
      });

      if (!skip) {
        await redirectAfterSave(res);
      }
    };

    const triggerSave = async () => {
      if (currentMode.value == "checklist") {
        await saveIncludingMachform(false);
      } else {
        await saveChanges(false);
      }
    };

    const backPre = () => {
      helpers.scrollToTop("stepTopView");

      let toBackMode = Steps[currentStep.value - 1].Mode;

      //set value now
      currentStep.value = currentStep.value - 1;

      if (toBackMode == "checklist") {
        iframeLoading.value = true;
      }

      if (helpers.hasRoles(["CLIENT", "SUB_CLIENT"])) {
        formData.LastStepValue = currentMode.value;
      }
    };

    const getAssessmentLink = computed(() => {
      var item = _.find(ServicesList.value, [
        "AssessmentServiceKey",
        formData.AssessmentServiceKey,
      ]);
      let url = item?.AssessmentChecklistUrl || "";
      url += `&mfsid=${helpers.generateRandomCharacters(32)}`;

      if (item?.AssessmentChecklistUrl && formData.MachformEntryKey) {
        url += `&row_entry_id=${formData.MachformEntryKey}`;
      } else {
        url += `&reset=1`; //for session reset
      }

      url += `&ReviewToken=${
        formData.ReviewToken ? formData.ReviewToken : item.ReviewToken
      }`;

      return url;
    });

    const iframeLoading = ref(false);

    const isFinished = async () => {
      let res = {};
      let index = 0;
      for (let row of Steps) {
        let resp = await dataOnly("/application/validate", {
          step: row.Mode,
          ...formData,
        });
        res[index] = resp ? true : false;
        index++;
      }
      stepStatus.value = res;
    };
    const navStep = async (toPage) => {
      if (
        typeof toPage == "number" &&
        (currentStep.value == toPage || !stepStatus.value[toPage])
      ) {
        return;
      }

      if (toPage < currentStep.value && stepStatus.value[toPage]) {
        toNext(toPage, true);
        return;
      }

      if (toPage != currentStep.value + 1) {
        var allPrevFinished = true;
        _.each(_.keys(stepStatus.value), function (s) {
          if (s < toPage && !stepStatus.value[s]) {
            allPrevFinished = false;
          }
        });
        if (!allPrevFinished) return;
      }

      toNext(toPage);
    };
    const toNext = async (toPage, byPassChecker) => {
      helpers.scrollToTop("stepTopView");

      let nextStep = typeof toPage == "number" ? toPage : currentStep.value + 1;
      let toNextMode = Steps[nextStep].Mode;

      if (
        !byPassChecker &&
        toNextMode == "preview" &&
        !helpers.hasRoles(["CLIENT", "SUB_CLIENT"])
      ) {
        return helpers.catchError({ Code: "MSG_ERR_ONLY_CLIENT_CAN_SUBMIT_THIS_FORM" });
      }

      try {
        if (!byPassChecker) {
          let res = await post("/application/validate", {
            step: currentMode.value,
            ...formData,
          });
          if (!isValidResponse(res)) return;

          if (currentMode.value == "checklist") {
            await ElMessageBox.confirm(
              translation.translate("MSG_CONFIRM_MACHFORM_HIT_SAVE"),
              translation.translate("TTL_WARNING"),
              {
                confirmButtonText: translation.translate("BTN_OKAY"),
                cancelButtonText: translation.translate("BTN_CANCEL"),
                type: "warning",
              }
            );
          }
        }

        var to = typeof toPage == "number" ? toPage : currentStep.value + 1;
        //set value now
        currentStep.value = to;

        if (toNextMode == "checklist") {
          iframeLoading.value = true;
        }

        if (helpers.hasRoles(["CLIENT", "SUB_CLIENT"])) {
          formData.LastStepValue = currentMode.value;
        }

        if (toNextMode == "contact") {
          // below fields should be pre-filled by aligned contact info from client profile
          await loadContactList();
        }

        await isFinished();
      } catch (err) {}
    };

    const toSubmit = async () => {
      try {
        let IsSigned = formData.Signature && formData.SignDate;
        if (!IsSigned) {
          helpers.catchError({ Code: "MSG_ERR_PLEASE_SIGN" });
          return;
        }

        let resp = await post("/application/checklist/validate", {
          MachformId: SelectedService.value.MachformId,
          MachformEntryKey: formData.MachformEntryKey,
        });
        if (!isValidResponse(resp)) {
          throw "ERR_CHECKLIST";
        }

        await ElMessageBox.confirm(
          translation.translate("MSG_WARNING_CONFIRM_DELETE"),
          translation.translate("TTL_WARNING"),
          {
            confirmButtonText: translation.translate("BTN_OKAY"),
            cancelButtonText: translation.translate("BTN_CANCEL"),
            type: "warning",
          }
        );
        let res = await post("/application/save", {
          ...formData,
          MachformId: SelectedService.value.MachformId,
          MachformEntryKey: formData.MachformEntryKey,
          IsSubmit: 1,
        });
        if (!isValidResponse(res)) return;

        helpers.showMessage({
          Code: "MSG_SUCC_APPLICATION_SUBMITTED",
          Message: "MSG_SUCC_APPLICATION_SUBMITTED",
        });

        await redirectAfterSave(res);
      } catch (err) {
        if (err && err == "ERR_CHECKLIST") {
          //redirect to checklist
          let index = _.findIndex(Steps, (x) => x.Mode == "checklist");
          if (index > -1) {
            navStep(index);
          }
        }
      }
    };

    let TypeOptions = (item) => {
      // if any other type is same as item type already, item can't be same as any other
      var itemIsLinked = _.find(formData.contactInfo, ["IsSameAsType", item.Type]);
      if (itemIsLinked) return [];

      return _.filter(ContactTypeList, (tp) => {
        // for each option, if corresponding member is same as other type already, item can be same as this option
        var person = _.find(formData.contactInfo, ["Type", tp.Type]);
        return tp.Type != item.Type && !person?.IsSameAsType;
      });
    };

    const isRequired = (row) => {
      let isEmpty = helpers.isEmptyObject(
        _.pick(row, ["PersonTitle", "Name", "Position", "MobileNumber", "Email"])
      );

      if (!isEmpty) return true;
      return ContactRequiredList.indexOf(row.Type) > -1 ? true : false;
    };

    const clear = (row) => {
      let rows = [
        "PersonTitle",
        "Name",
        "Fax",
        "Position",
        "MobileNumber",
        "Email",
        "IsSameAsType",
      ];
      _.each(rows, (f) => {
        row[f] = "";
      });
    };

    let getContactInfo = computed(() => {
      return _.map(formData.contactInfo, (r) => {
        if (r.IsSameAsType) {
          let find = _.find(formData.contactInfo, (a) => a.Type == r.IsSameAsType);
          if (find) {
            let pick = _.pick(find, [
              "PersonTitle",
              "Name",
              "Position",
              "MobileCode",
              "MobileNumber",
              "Fax",
              "Email",
            ]);
            return { ...r, ...pick };
          }
        }
        return { ...r };
      });
    });

    const IsShowSignature = ref(true);

    const handleTabChange = () => {
      formData.SignDate = null;
      formData.Signature = null;
      setTimeout(() => {
        IsShowSignature.value = formData.SignatureType == "SIGN" ? true : false;
      }, 100);
    };

    const ifFound = (value) => {
      let found = _.find(formData.formDocuments, (r) => {
        return r.DocuType == value;
      });
      return found ? true : false;
    };

    return {
      triggerSave,
      dialogResponse,
      showDialog,
      IsShowSignature,
      ifFound,
      currentMode,
      Steps,
      sucUploadSignature,
      handleTabChange,
      mq,
      OrganizationData,
      SelfDeclarationData,
      TermsData,
      getContactInfo,
      clear,
      TypeOptions,
      isRequired,
      iframeLoading,
      getAssessmentLink,
      savingSignature,
      BoundaryProgram,
      LangList,
      changeAmount,
      changeSignature,
      loaded,
      currentStep,
      formData,
      applicantSign,
      //changeBoundary,
      changeService,
      //regisFieldChecked,
      updateScope,
      BusinessStaff,
      ServicesList,
      undo,
      save,
      backPre,
      stepStatus,
      isFinished,
      navStep,
      toNext,
      saveChanges,
      TotalRegisFee,
      PersonTitleOpts,
      DocuTypes,
      sucUploadDocu,
      sucUploadProposal,
      CurrencyOptions,
      SelectedService,
      toSubmit,
      RegionList,
      IndustryList,
    };
  },
};
</script>

<style>
.currency-label {
  width: unset !important;
  min-width: 60px;
}

.on-behalf-input {
  display: flex;
}

.on-behalf-input .el-input__inner {
  height: auto;
  min-height: 40px;
  width: 40%;
}
.on-behalf-input.full-width .el-input__inner {
  width: 100%;
}

.on-behalf-input .el-input-group__append {
  flex: 1;
  white-space: break-spaces;
  padding-top: 5px;
  padding-bottom: 5px;

  display: flex;
  align-items: center;
}
</style>
