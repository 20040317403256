import countryList from '@/country.json'
import _ from 'lodash'
import { environment } from '@/env.json'
import { tinymce } from '@/tinymce.json'

let configs = {
  countryList: _.map(countryList, (r) => {
    return {
      label: r.name,
      key: r['alpha-3']
    }
  }),
  baseURL: '',
  cmsURL: '',
  mpgsURL: '',
  environment,
  tinymce,
  title: 'HKQAA',
  appId: 'hkqaa-cert-app-1',
  duration: 10000,
  requestTokenSecret: 'rdh12QZ1',
  baseURLInfo: {
    local: 'http://localhost:3000/api',
    development: 'https://hkqaa-3asry4a20g8ldeohar7fwrp5bdp9z7u9.codaplant.com/api',
    production: 'https://iassurance.hkqaa.org/api'
  },
  cmsURLInfo: {
    local: 'https://hkqaa-modx.codaplant.com/',
    development: 'https://hkqaa-modx.codaplant.com/',
    production: 'https://iassurance-cms.codaplant.com/'
  },
  mpgsURLInfo: {
    local: 'https://ap-gateway.mastercard.com/static/checkout/checkout.min.js',
    development: 'https://ap-gateway.mastercard.com/static/checkout/checkout.min.js',
    production: 'https://ap-gateway.mastercard.com/static/checkout/checkout.min.js'
  },
  axiosTimeout: 300000,
  tokenSession: 'hkqaa-cert-web-app',
  encryptionSecret: '?Qbz34s_!BJUGZ9CYTkpAa!x6VxKE8'
}

if (window.location.hostname == 'localhost') {
  configs.environment = 'local'
} else if (window.location.hostname.includes('.codaplant.com')) {
  configs.environment = 'development'
} else {
  configs.environment = 'production'
}

configs.baseURL = configs.baseURLInfo[configs.environment]
configs.cmsURL = configs.cmsURLInfo[configs.environment]
configs.mpgsURL = configs.mpgsURLInfo[configs.environment]

export default configs
