let extended = {
  helpTitle: 'TTL_HELP',
  helpText: 'TTL_HELP_CONTENT',
  chatServer: 'https://gospeeding.com/chat',
  langList: [
    { name: 'EN', code: 'EN', altCode: 'EN' },
    { name: '繁', code: 'CHT', altCode: 'TC' },
    { name: '简', code: 'CHS', altCode: 'SC' }
  ],
  TextSizesList: [
    { value: 'small', class: 'small' },
    { value: 'medium', class: 'medium' },
    { value: 'big', class: 'big' }
  ],
  defaultSize: 10,
  pageSizeOptions: [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
    // { value: 9999, label: 'LBL_ALL' }
  ],
  certificateStatusStyle: {
    PUBLISHED: 'success',
    TERMINATED: 'danger',
    SUSPENDED: 'danger',
    ACTIVE: 'success',
    PENDING: 'info',
    DELETED: 'danger',
    EXPIRED: 'danger',
    RENEWAL: 'warning'
  },
  applicationStatusStyle: {
    DRAFT: 'info',
    INPROGRESS: 'info',
    PENDING: 'warning',
    UNDERREVIEW: 'primary',
    RETURNED: 'danger',
    CANCELLED: 'danger',
    ARCHIVED: 'danger',
    CERTIFIED: 'success',
    COMPLETED: 'success',
    REJECTED: 'danger',
    REJECTED_FLOW: 'primary',
    REJECTED_STAFF: 'danger'
  },
  validActionMapping: {
    ASSESSOR: {
      button: 'BTN_RECOMMEND',
      title: 'BTN_RECOMMEND_APPLICATION'
    },
    ASSESSOR_ONSITE: {
      button: 'BTN_RECOMMEND',
      title: 'BTN_RECOMMEND_APPLICATION'
    },
    INDEPREVIEWER: {
      button: 'BTN_AGREE',
      title: 'BTN_AGREE_RECOMMENDATION'
    },
    COO: {
      button: 'BTN_ENDORSE',
      title: 'BTN_ENDORSE_RECOMMENDATION'
    },
    CEO: {
      button: 'BTN_APPROVE',
      title: 'BTN_APPROVE_RECOMMENDATION'
    }
  },
  alertMapping: {
    APPROVE: {
      button: 'BTN_CONFIRM',
      title: 'BTN_CONFIRM_TO_REVIEW'
    },
    TOPAID: {
      button: 'BTN_CHANGE_TO_PAID',
      title: 'TTL_CONFIRM_ACTION'
    },
    PAY_NOW: {
      button: 'BTN_CONTINUE',
      title: 'TTL_PAYMENT'
    },
    CANCEL: {
      button: null,
      title: 'TTL_CANCEL_APPLICATION'
    },
    REJECT_ASSESSOR: {
      button: 'BTN_REJECT',
      title: 'TTL_REJECT_APPLICATION'
    },
    TOUNPAID: {
      button: 'BTN_CHANGE',
      title: 'TTL_CHANGE_TO_UNPAID'
    }
  },
  navigationLinks: [
    {
      Name: 'TTL_DASHBOARD',
      Icon: 'fas fa-home',
      Path: '/dashboard',
      Indexes: ['/dashboard'],
      Role: ['ADMIN', 'SUB_ADMIN', 'IT', 'SCHEDULING', 'BUSINESS', 'FINANCE', 'ASSESSOR', 'COO', 'DEPUTYCEO', 'CEO', 'CLIENT', 'SUB_CLIENT']
    },
    {
      Name: 'TTL_SERVICES',
      Icon: 'fas fa-coins',
      Path: '/assessment_services',
      Indexes: ['/assessment_services'],
      Role: ['ADMIN', 'SUB_ADMIN', 'IT']
    },
    {
      Name: 'TTL_APPLICATIONS',
      Icon: 'fas fa-list',
      Path: '/applications',
      Indexes: ['/applications'],
      Role: ['ADMIN', 'SUB_ADMIN', 'IT', 'FINANCE', 'BUSINESS', 'SCHEDULING', 'ASSESSOR', 'COO', 'DEPUTYCEO', 'CEO', 'CLIENT', 'SUB_CLIENT']
    },
    {
      Name: 'TTL_MANAGE_CERTS',
      Icon: 'fas fa-store',
      Path: '/generated_certs',
      Indexes: ['/generated_certs'],
      Role: ['ADMIN', 'SUB_ADMIN', 'FINANCE', 'IT', 'ASSESSOR', 'BUSINESS', 'SCHEDULING', 'COO', 'DEPUTYCEO', 'CEO', 'CLIENT', 'SUB_CLIENT']
    },
    {
      Name: 'TTL_CLIENTS',
      Icon: 'fas fa-user-cog',
      Path: '/users',
      Indexes: ['/users'],
      Role: ['ADMIN', 'SUB_ADMIN', 'IT', 'BUSINESS', 'SCHEDULING', 'FINANCE']
    },
    {
      Name: 'TTL_CLIENT_SUB_ACCOUNT',
      Icon: 'fas fa-user-cog',
      Path: '/child',
      Indexes: ['/child'],
      Role: ['CLIENT']
    },
    {
      Name: 'TTL_SETTING',
      Icon: 'fas fa-cog',
      Path: '/staffs',
      Indexes: ['/staffs', '/business_teams', '/tags', '/email_notification', '/translation', '/country'],
      Role: ['ADMIN', 'SUB_ADMIN', 'IT']
    },
    {
      Name: 'TTL_LOGS',
      Icon: 'ni ni-sound-wave',
      Path: '/logs',
      Indexes: ['/logs'],
      Role: ['ADMIN', 'SUB_ADMIN', 'IT']
    }
  ],
  datePickerShortcuts: [
    {
      text: 'Last 7 days',
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        return [start, end]
      }
    },
    {
      text: 'Last 30 days',
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        return [start, end]
      }
    },
    {
      text: 'Last 60 days',
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 60)
        return [start, end]
      }
    },
    {
      text: 'Last 90 days',
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        return [start, end]
      }
    }
  ],
  actionLogMapping: {
    '/api/application/validate': 'Validate application steps',
    '/api/application/view': 'View application details',
    '/api/application/save': 'Save application',
    '/api/application/count': 'Fetched the number of applications',
    '/api/application/cancel': 'Cancel application',
    '/api/application/archive': 'Archive application',
    '/api/application/unarchive': 'Unarchive application',
    '/api/application/paynow': 'Pay for application now',
    '/api/application/payonline': 'Pay for application now - online payment',
    '/api/application/refund': 'Refund payment - online payment',
    '/api/application/onlinepayment/cancel/refund': 'Refund payment - online payment',
    '/api/application/onlinepayment/cancel/void': 'Void payment (No refund) - online payment',
    '/api/application/comment': 'Add comment for application',
    '/api/application/paid': 'Toggle application to paid',
    '/api/application/change/payment/date': 'Confirm payment date',
    '/api/application/change/tranx': 'Changed Transaction No.',
    '/api/application/change/machform': 'Changed Machform Entry No.',
    '/api/application/unpaid': 'Toggle application to unpaid',
    '/api/application/generate': 'Generate certificate',
    '/api/application/assign': 'Assign staff to application',
    '/api/application/progress/approve': 'Approve application progress',
    '/api/application/progress/reject': 'Return application progress',
    '/api/application/rejected': 'Reject application progress',
    '/api/application/multiple/generate': 'Generate multiple certificates',
    '/api/application/multiple/approve': 'Approve progress of multiple applications',
    '/api/application/multiple/assign': 'Assign staff to multiple applications',
    '/api/application/review': 'Complete contract review',
    '/api/application/list': 'Fetched application list',
    '/api/application/certificate/list': 'Fetched certificate list',
    '/api/application/certificate/status': 'Toggle certificate status',
    '/api/application/certificate/renew': 'Renew certificate',
    '/api/application/certificate/regenerate': 'Regenerate certificate',
    '/api/application/certificate/view': 'View certificate details',
    '/api/application/certificate/resume': 'Resume certificate',
    '/api/application/disclosure/upload': 'Disclosure File Uploaded',
    '/api/application/certificate/upload': 'Certificate File Uploaded',
    '/api/application/crb/confirm': 'CRB Flow Confirmed',

    '/api/attachment/save': 'Uploaded an Attachment',
    '/api/application/publish': 'Application mark as completed',

    '/api/assessment/list': 'Fetched service list',
    '/api/assessment/checklist': 'Fetched assessment checklist',
    '/api/assessment/sync/machform': 'Sync machform list',
    '/api/assessment/template': 'Fetched template list',
    '/api/assessment/industry': 'Fetched industry list',
    '/api/assessment/save': 'Save service',
    '/api/assessment/edit_mf': 'Edit machform ID',
    '/api/assessment/checklist/submission': 'Fetched submission of checklist',
    '/api/assessment/action': 'Toggle service status',
    '/api/assessment/view': 'View service details',
    '/api/assessment/tag/submit': 'Update service tags',
    '/api/assessment/tag/remove': 'Remove service tags',
    '/api/user/update/terms': 'Accepted Terms Version',
    '/api/user/sub/remove': 'Child Account Removed',
    '/api/user/sub/invite': 'Child Account Invited',
    '/api/application/machform/update': 'Assessment Checklist Update (Machform)',

    '/api/auth/config': 'Fetched configuration',
    '/api/auth/web/login': 'User login (web)',
    '/api/auth/backend/login': 'User login (backend)',
    '/api/auth/register': 'Register account',
    '/api/auth/activate': 'Activate account',
    '/api/auth/exchange': 'Fetched details of inviter',

    '/api/business/team/submit': 'Business team created',
    '/api/business/team/remove': 'Business team deleted',
    '/api/business/team/member': 'Update members of business team',
    '/api/business/team/list': 'Business team list',

    '/api/cms/get': 'Get cms data',

    '/api/comment/list': 'Fetched comment list',
    '/api/comment/save': 'Save comment',
    '/api/comment/remove': 'Remove comment',

    '/api/export/download': 'Download file',
    '/api/export/document': 'Export document',

    '/api/image/view': 'View image',

    '/api/media/upload': 'Upload file',
    '/api/media/upload/tmp': 'Upload temporary file',
    '/api/media/download': 'Download file',
    '/api/media/upload/tinymce': 'Upload tinymce file',

    '/api/public/industry': 'Fetched public industry list',
    '/api/public/service': 'Fetched public service list',
    '/api/public/certificate': 'Fetched public certificate list',

    '/api/stage/list': 'Fetched stage list',
    '/api/stage/view': 'View stage details',
    '/api/stage/submit': 'Update stage details',
    '/api/stage/assign': 'Assign email list of the stage',

    '/api/stripe/connect': 'Fetched stripe connect info',
    '/api/stripe/connect/link': 'Trying to link stripe connect',
    '/api/stripe/disconnect': 'Disconnect stripe',

    '/api/translation/public': 'Fetched specific translations',
    '/api/translation/public/all': 'Fetched all translations',
    '/api/translation/list': 'Fetched translation list',
    '/api/translation/submit': 'Update translation',

    '/api/user/update/profile': 'User profile updated',
    '/api/user/forgot': 'Forgot password',
    '/api/user/info': 'Fetched user details',
    '/api/user/view': 'View user details',
    '/api/user/password': 'Update account password',
    '/api/user/roles': 'Fetched user roles list',
    '/api/user/list': 'Fetched list of users',
    '/api/user/invite': 'Send invitation to client',
    '/api/user/update': 'User profile updated',
    '/api/user/remove': 'User account deleted',
    '/api/user/create': 'User account created',
    '/api/user/notification': 'Fetched unread count',
    '/api/user/notification/read': 'Read a notification',
    '/api/user/notification/read/all': 'Read all notifications',
    '/api/user/notification/list': 'Fetched notification list',
    '/api/user/reset': 'Reset password'
  }
}

export default extended
