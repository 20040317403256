import { translation } from '@/utils/translation.js'
import { extendedConfigs, configs } from '@/utils/configs'
import { get } from '@/utils/axios'
import { store } from '@/store'
import { notify } from '@kyvg/vue3-notification'
import dayjs from 'dayjs'
import jwt from 'jsonwebtoken'
import { useMq } from 'vue3-mq'
import { router } from '@/utils/router'

const helpers = {
  async redirect({ path, urlParams, router }) {
    let Redirect = store.getters['user/getData']?.Redirect
    if (Redirect) {
      path = Redirect.split('?')[0]
      urlParams = helpers.getObjectFromQueryString(Redirect)
    }

    //reset now
    await store.dispatch('user/setData', { Redirect: null })

    router.replaceRoute(path, urlParams)
  },
  async getTCVersion() {
    let TC = await get('/cms/get/terms-and-condition.json?lang=EN')
    let Privacy = await get('/cms/get/privacy.json?lang=EN')

    return {
      TermsVersion: TC?.versionx?.version_id || null,
      TermsVersionDate: TC?.versionx?.saved || null,
      PrivacyVersion: Privacy?.versionx?.version_id || null,
      PrivacyVersionDate: Privacy?.versionx?.saved || null
    }
  },
  async checkToCookies() {
    //only check for EN version
    let cookies = await get('/cms/get/consent-for-cookies.json?lang=EN', {}, true)
    let cookieVersion = cookies?.versionx?.version_id

    let { IsCookieConsent, CookieVersion } = store.getters['consent/getData']
    if (!IsCookieConsent || CookieVersion != cookieVersion) {
      await store.dispatch('consent/setData', {
        IsCookieConsent: false,
        CookieVersion: cookieVersion
      })
      return true
    }

    return false
  },
  async checkForTC() {
    let UserData = store.getters['user/getData']?.UserData
    if (!UserData) return

    let { PrivacyVersion, TermsVersion } = UserData

    let TC = await get('/cms/get/terms-and-condition.json?lang=EN', {}, true)
    let Privacy = await get('/cms/get/privacy.json?lang=EN', {}, true)

    TC = TC?.versionx?.version_id
    Privacy = Privacy?.versionx?.version_id

    if (TC != TermsVersion || Privacy != PrivacyVersion) {
      await store.dispatch('user/setData', { IsTCUpdated: true })
    }

    return
  },
  getTotalCount(str) {
    return str.replace(/\n/g, '').length
  },
  autoTrimParagraph(str = '', maxRow = 3, maxChar = 60) {
    let rows = str.split('\n')
    let index = 0
    while (rows[index]) {
      let row = rows[index] //use index instead

      if (row.length <= maxChar) {
        index++
        continue
      }

      if (!(index < maxRow - 1)) {
        rows[index] = row.slice(0, maxChar)
        index++
        continue
      }

      if (row.indexOf(' ') > -1) {
        let words = row.split(' ')
        let newRows = []
        let newRow = ''

        for (let word of words) {
          if (newRow.length + (word.length + 1) > maxChar) {
            newRows.push(newRow)
            newRow = ''
          }
          newRow += (newRow ? ' ' : '') + word
        }

        if (newRow.length > 0) {
          newRows.push(newRow)
        }

        rows.splice(index, 1, ...newRows)
      } else {
        rows[index] = row.slice(0, maxChar)
        rows[index + 1] = row.slice(maxChar) + (rows[index + 1] || '')
      }
      index++
    }

    //rows = rows.map((row) => row.trim())
    return rows.splice(0, maxRow)
  },
  isSmall() {
    let mq = useMq()

    return mq.current == 'xsmall' || mq.current == 'small'
  },
  verifyToken(token) {
    let payload = null
    try {
      payload = jwt.verify(token, configs.requestTokenSecret)
    } catch (err) {
      payload = null
    }
    return payload
  },
  signToken(obj) {
    return jwt.sign(obj, configs.requestTokenSecret)
  },
  stripTags(str) {
    if (!str) return ''
    return str.replace(/(<([^>]+)>)/gi, '')
  },
  replaceAllEmail(str) {
    let list = str.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)
    _.each(list, (email) => {
      var re = new RegExp(email, 'g')
      str = str.replace(re, `<a href="mailto:${email}">${email}</a>`)
    })

    return str
  },
  scrollToTop(id) {
    let doc = document.getElementById(id)

    if (doc) {
      setTimeout(() => {
        doc.scrollTop = 0
      }, 0)
    }
  },
  randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  },
  handleViewApplication(router, row, opt) {
    if (opt?.className?.indexOf('action-col') > -1) return

    let data = { key: row.ApplicationKey }

    if (!row?.IsAbleToEditForm) {
      return helpers.showWarning({
        Code: 'LBL_NOT_AVAILABLE_FOR_EDIT',
        Message: 'LBL_NOT_AVAILABLE_FOR_EDIT'
      })
    }

    if (row.ApplicationStatusCode == 'INPROGRESS') {
      // from client comment: Business Staff also has right to amend the in-progress form.
      // if (helpers.hasRoles(['CLIENT', 'SUB_CLIENT', 'BUSINESS', 'ADMIN', 'SUB_ADMIN'])) {
      //   router.changeRoute('/application/edit', data)
      // }
      // return
    }

    router.changeRoute('/viewform', data)
  },
  getSpecialChars() {
    let { specialChars } = store.getters['config/getData']?.envConf
    return specialChars
  },
  getWideBg(isShow = true) {
    if (!isShow) return ''

    let Page = store.getters['page/getData']
    if (!Page.WebBackground) return ''
    return `url(${configs.cmsURL}${Page.WebBackground})`
  },
  validateEmail(email) {
    let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
  },
  getImageFileTypes() {
    let { mimeTypeList } = store.getters['config/getData']?.envConf
    return mimeTypeList.image.join(', ')
  },
  getGeneralFileTypes() {
    let { mimeTypeList } = store.getters['config/getData']?.envConf
    return mimeTypeList.document.join(', ')
  },
  getCRBFileTypes() {
    let { mimeTypeList } = store.getters['config/getData']?.envConf
    return mimeTypeList.crb.join(', ')
  },
  getUserData() {
    let User = store.getters['user/getData']?.UserData
    return User || {}
  },
  getRole() {
    let roles = store.getters['user/getRole']
    return roles[0]
  },
  getWidth(lang) {
    let maxLength = helpers.getMaxCharScopeRequirement(lang, true)

    let newWidth = 0
    // let div = document.createElement('span')
    // div.classList.add('el-textarea__inner')
    // div.innerText = 'w'.repeat(maxLength)
    // div.style.visibility = 'hidden'
    // div.style.whiteSpace = 'pre'
    // div.style.padding = '0px'
    // div.style.display = 'inline'

    // document.body.appendChild(div)
    // newWidth = div.offsetWidth
    // document.body.removeChild(div)

    const charWidth = 9 // average width of a character in pixels, adjust as needed
    const padding = 0 // additional padding in pixels
    newWidth = maxLength * charWidth + padding
    return `${newWidth}px`
  },
  getMaxCharScopeRequirement(cc, row = false) {
    let { ScopeRequirement } = store.getters['config/getData']?.envConf
    let { rLimit, charLimit } = ScopeRequirement
    let maxChar = charLimit[cc]

    if (row) {
      return charLimit[cc]
    }

    return maxChar * rLimit
  },
  getScopeRequirement() {
    let { ScopeRequirement } = store.getters['config/getData']?.envConf
    return ScopeRequirement
  },
  getCharLimit() {
    let { charLimit } = store.getters['config/getData']?.envConf
    return charLimit
  },
  hasRoles(CheckRole) {
    let roles = store.getters['user/getRole']
    let toCheckRole = Array.isArray(CheckRole) ? CheckRole : [CheckRole]

    let found = false
    for (let role of roles) {
      if (!found && toCheckRole.indexOf(role) > -1) found = true
    }
    return found
  },
  notAnyRoles(CheckRole) {
    let roles = store.getters['user/getRole']

    let found = false
    for (let role of roles) {
      if (!found && CheckRole.indexOf(role) > -1) found = true
    }
    return !found
  },
  getMobileNumber(item) {
    let phone = []
    if (item.PhoneCode) phone.push(item.PhoneCode)
    if (item.PhoneNo) phone.push(item.PhoneNo)
    return phone.join('')
  },
  required(fields, Bale) {
    let valid = true
    for (let i = 0; i < fields.length; i++) {
      let item = fields[i]
      if (helpers.isBlank(Bale[item])) {
        valid = false
      }
    }
    return valid
  },
  getDatePeriodFilter(Filter) {
    if (Filter.DatePeriod && Filter.DatePeriod.length == 2) {
      let From = helpers.formatDateValue(Filter.DatePeriod[0])
      let To = helpers.formatDateValue(Filter.DatePeriod[1])

      return { From, To }
    }
    return {}
  },
  formatDateValue(date) {
    return dayjs(date).format('YYYY-MM-DD')
  },
  formatDisclosureDate(date) {
    if (!date) return '---'
    return dayjs(date).format('MM-DD-YYYY')
  },
  actionMapping(action) {
    return extendedConfigs.actionLogMapping[action] || action
  },
  getFullName(item, showMiddleName, placeholder, isAddRole = false) {
    let tmpName = []
    if (item && item.FirstName) tmpName.push(item.FirstName)
    if (item && item.MiddleName && showMiddleName) tmpName.push(item.MiddleName)
    if (item && item.LastName) tmpName.push(item.LastName)
    return `${isAddRole && item.UserRole ? `${translation.translate(`LBL_ROLE_${item.UserRole}`)}, ` : ``}${tmpName.join(' ') || placeholder || ''}${isAddRole && item.UserRole ? `: ` : ``}`
  },
  arrToObject(arr) {
    let obj = {}
    for (let c of arr) {
      obj[c] = true
    }
    return obj
  },
  getFullNameReverse(item, showMiddleName, placeholder) {
    let tmpName = []
    if (item && item.LastName) tmpName.push(item.LastName)
    if (item && item.FirstName) tmpName.push(item.FirstName)
    return tmpName.join(', ') || placeholder || ''
  },
  getPeriodMode(searchObj) {
    if (searchObj.DatePeriod && searchObj.DatePeriod.length == 2) {
      searchObj.From = helpers.formatDateValue(searchObj.DatePeriod[0])
      searchObj.To = helpers.formatDateValue(searchObj.DatePeriod[1])
      delete searchObj.DatePeriod
    }

    return searchObj
  },
  mapFieldByCC(obj, f_name) {
    var cCode = translation.getLanguage()

    return obj[f_name + cCode] || obj[f_name + 'EN'] || obj[f_name] || ''
  },
  isAplhaNumeric(val) {
    var regex = /^[0-9a-zA-Z]+$/
    var ret = false
    if (val.match(regex)) {
      ret = true
    } else {
      ret = false
    }
    return ret
  },
  cutText(text, length) {
    if (text.split(' ').length > 1) {
      const string = text.substring(0, length)
      const splitText = string.split(' ')
      splitText.pop()
      return splitText.join(' ') + '...'
    } else {
      return text
    }
  },
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ''
    }
  },
  isMobile() {
    let ret = false
    // device detection
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      ret = true
    }
    return ret
  },
  padStart(value, numberOfPad, padChar) {
    let pattern = ''
    for (let i = 0; i < numberOfPad; i++) {
      pattern += padChar
    }
    return (pattern + value).slice(-numberOfPad)
  },
  formatMobile({ MobileCode = '+852', MobileNumber }) {
    return `${MobileCode}-${MobileNumber}`
  },
  formatDateTime(date) {
    if (typeof date === 'undefined' || date === null) return '---'

    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      if (translation.getLanguage() == 'EN') {
        return dayjs(date).format('MMM D, YYYY HH:mm:ss')
      } else {
        return dayjs(date).format('YYYY[年]M[月]D[日] HH:mm:ss')
      }
    } else {
      return '--'
    }
  },
  formatDateValue(date) {
    if (typeof date === 'undefined' || date === null) return null

    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).format('YYYY-MM-DD')
    } else {
      return null
    }
  },
  getObjectFromQueryString(queryString) {
    if (queryString.indexOf('?') > -1) {
      queryString = queryString.split('?')[1]
    }
    var pairs = queryString.split('&')
    var result = {}
    pairs.forEach(function(pair) {
      pair = pair.split('=')
      result[pair[0]] = decodeURIComponent(pair[1] || '')
    })
    return result
  },
  generateRandomCharacters(count) {
    count = count || 6
    let text = ''
    let possible = 'ABCDEFGHJKMNOPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz'

    for (let i = 0; i < count; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return text
  },
  formatDate(date) {
    if (typeof date === 'undefined' || date === null) return '---'

    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      if (translation.getLanguage() == 'EN') {
        return dayjs(date).format('MMM D, YYYY')
      } else {
        return dayjs(date).format('YYYY[年]M[月]D[日]')
      }
    } else {
      return '--'
    }
  },
  formatNumber(total) {
    if (!total) return '---'
    return parseFloat(total)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
      .replace('.00', '')
  },
  formatPrice(price, currencyCode) {
    if (price && parseFloat(price) > 0) {
      return (
        (currencyCode ? currencyCode : '') +
        ' ' +
        (price
          ? parseFloat(price)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')
          : '0.00')
      )
    } else {
      return '0.00'
    }
  },
  iFrameHeight: (e, iframeId) => {
    if (e.data !== undefined) {
      //adjust the height of the iframe
      var new_height = 0

      try {
        new_height = Number(e.data.replace(/.*mf_iframe_height=(\d*(\.\d+)?)(?:&|$)/, '$1'))

        if (!isNaN(new_height) && new_height > 0) {
          new_height += 100
          //height has changed, update the iframe
          document.getElementById(iframeId).height = new_height
          //just to make sure the height is being applied
          document.getElementById(iframeId).setAttribute('style', 'width:100%;border:none;height:' + new_height + 'px !important')
        }
      } catch {}
    }
  },
  isBlank: (value) => {
    if (value === null || value === undefined || value === '' || value.length === 0) {
      return true
    } else {
      return false
    }
  },
  isValidTableId: (val) => {
    return val && parseInt(val) > 0
  },
  isUndefined: (val) => {
    return typeof val === 'undefined'
  },
  isInteger: (val) => {
    return helpers.isUndefined(val) ? false : Number.isInteger(!isNaN(val) ? Number(val) : null)
  },
  isNumeric: (num) => {
    return (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) && !isNaN(num)
  },
  isAlphaNumeric: (str) => {
    return /^[a-zA-Z0-9]+$/gi.test(str)
  },
  isString: (val) => {
    return typeof val === 'string' || val instanceof String
  },
  isObject: (val) => {
    return !helpers.isUndefined(val) && val.toString() === '[object Object]'
  },
  isArray: (val) => {
    return val instanceof Array
  },
  isEmpty: (val) => {
    return helpers.isBlank(val) || val.length <= 0 || Object.keys(val).length <= 0
  },
  isEmptyObject: (obj) => {
    for (let key in obj) {
      if (obj[key] instanceof Object === true) {
        if (helpers.isEmptyObject(obj[key]) === false) return false
      } else {
        if (obj[key] && obj[key].length !== 0) return false
      }
    }
    return true
  },
  isJSON: function(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }

    return true
  },
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  renderTags(tags) {
    let { TagOpts } = store.getters['config/getData']?.envConf
    if (!tags) return []

    return _.filter(
      _.map(tags.split(','), (r) => {
        return _.find(TagOpts, (a) => a.TagCode == r)
      }),
      (a) => a
    )
  },
  objToQueryString(obj) {
    const keyValuePairs = []
    for (let i = 0; i < Object.keys(obj).length; i += 1) {
      if (!Object.values(obj)[i]) continue
      if (typeof Object.values(obj)[i] === 'undefined') continue
      keyValuePairs.push(`${encodeURIComponent(Object.keys(obj)[i])}=${encodeURIComponent(Object.values(obj)[i])}`)
    }

    if (keyValuePairs.length == 0) return ''
    return '?' + keyValuePairs.join('&')
  },
  isIframeProcessing() {
    return store.getters['page/getData']?.isIframeProcessing
  },
  isSubmitting() {
    return store.getters['page/getData']?.isSubmitting || store.getters['page/getData']?.isExporting
  },
  isFetching() {
    return store.getters['page/getData']?.isFetching
  },
  showMessage({ Code, Message }) {
    //success message
    let text = translation.translate(Code, Message)
    notify({ duration: configs.notifDuration, text, title: 'Success', type: 'success' })
  },
  showWarning({ Code, Message }) {
    //warning message
    let text = translation.translate(Code, Message)
    let title = translation.translate('TTL_WARNING')
    notify({ duration: configs.notifDuration, text, title, type: 'warn' })
  },
  getInitials(item) {
    let name = helpers.getFullName(item)
    var matches = name.match(/\b(\w)/g)
    var acronym = matches.slice(0, 2).join('')
    return acronym
  },
  catchError(err, showNotifications = true) {
    //error message
    let Code = err?.Code || err?.response?.data?.Code
    let Message = err?.Message || err?.response?.data?.Message

    if (Code || Message) {
      let text = translation.translate(Code, Message)
      notify({ duration: configs.notifDuration, text, title: 'Error', type: 'error' })
    } else {
      //err
    }

    if (err?.response?.status == 401 || Code == 'ERR_AUTH') {
      router.replaceRoute('/logout')
    }
  },
  formatOrgBranch(obj) {
    let orgName = helpers.formatName(obj, 'OrgName')
    let branchName = helpers.formatName(obj, 'BranchName')
    return `${orgName}${branchName ? ` - ${branchName}` : ``}`
  },
  formatServiceName(obj) {
    let serviceName = helpers.formatName(obj, 'Name')
    let serviceNameExtra = helpers.formatName(obj, 'NameExtra')
    return `${serviceName}${serviceNameExtra ? ` - ${serviceNameExtra}` : ``}`
  },
  formatName(obj, field, returnNull) {
    if (typeof obj === 'undefined' || !obj) return ''
    return obj[field + translation.getLanguage()] || obj[field] || ''
  },
  mapField(key, list, field) {
    var obj = _.find(list, function(item) {
      return item.key == key
    })
    return obj ? obj[field] : ''
  },
  mapLabel(val, list) {
    if (typeof val === 'undefined') return ''

    var obj = _.find(list, function(item) {
      return item.value == val
    })
    return obj ? translation.translate(obj.label) : ''
  }
}

const install = (app) => {
  app.config.globalProperties.$h = helpers
}

export { install as default, helpers }
