<template>
  <div>
    <div
      :class="{
        'pt-8': !$h.hasRoles(['CLIENT', 'SUB_CLIENT']),
        'pt-7': $h.hasRoles(['CLIENT', 'SUB_CLIENT']),
      }"
      class="container-fluid pb-5"
    >
      <el-collapse class="expand-main mb-3 shadow">
        <el-collapse-item class="expand-item">
          <template #title>
            <div class="row pl-3">
              <div class="col-12 d-flex align-items-center">
                <i class="fas fa-search"></i>
                <h3 class="mb-0 ml-2">{{ $t.translate("LBL_SEARCH") }}</h3>
              </div>
            </div>
          </template>
          <card shadow type="secondary" class="">
            <div class="">
              <div class="row">
                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.ApplicationNo"
                    :label="$t.translate('LBL_APPLICATION_NO')"
                    input-classes="form-control-alternative"
                  />
                </div>

                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.Applicant"
                    :label="$t.translate('LBL_APPLICANT')"
                    input-classes="form-control-alternative"
                  />
                </div>

                <template v-if="!$h.hasRoles(['CLIENT', 'SUB_CLIENT'])">
                  <div class="col-lg-6">
                    <base-input
                      v-on:keyup.enter="filterAction"
                      v-model="Filter.Assessor"
                      :label="$t.translate('LBL_ASSESSOR')"
                      input-classes="form-control-alternative"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      v-on:keyup.enter="filterAction"
                      v-model="Filter.IndependentReviewer"
                      :label="$t.translate('LBL_INDEPENDENT_REVIEWER')"
                      input-classes="form-control-alternative"
                    />
                  </div>
                </template>

                <div class="col-lg-6">
                  <label class="form-control-label">{{
                    $t.translate("FRM_CREATED_DATE_RANGE")
                  }}</label>
                  <div class="block form-group d-flex align-items-center">
                    <el-date-picker
                      v-model="Filter.DatePeriod"
                      type="daterange"
                      class="w-100"
                      unlink-panels
                      range-separator="-"
                      :start-placeholder="$t.translate('FRM_START_DATE')"
                      :end-placeholder="$t.translate('FRM_END_DATE')"
                      :shortcuts="$configs.datePickerShortcuts"
                      popper-class="cus-drange"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-lg-6">
                  <el-button type="primary" @click="filterAction">
                    {{ $t.translate("BTN_SEARCH") }}
                  </el-button>
                  <el-button type="primary" class="ml-3" @click="resetParams">
                    {{ $t.translate("BTN_RESET") }}
                  </el-button>
                </div>
              </div>
            </div>
          </card>
        </el-collapse-item>
      </el-collapse>
      <div class="d-flex justify-content-end">
        <template
          v-if="
            $h.hasRoles([
              'CLIENT',
              'SUB_CLIENT',
              'COO',
              'DEPUTYCEO',
              'CEO',
              'ADMIN',
              'SUB_ADMIN',
              'SCHEDULING',
              'FINANCE',
            ]) || userData?.IsGrantGeneration
          "
        >
          <el-button
            type="primary"
            class="mb-3"
            v-if="$h.hasRoles(['CLIENT', 'SUB_CLIENT'])"
            @click="addCase"
          >
            {{ $t.translate("BTN_ADD_APPLICATION") }}
          </el-button>

          <el-dropdown
            v-if="!$h.hasRoles(['CLIENT', 'SUB_CLIENT', 'DEPUTYCEO', 'COO', 'CEO'])"
            class="mb-3"
            trigger="click"
            :hide-on-click="true"
            @command="(comd) => exportCmd(comd, $refs)"
          >
            <el-button type="default" style="margin-left: 10px">
              <i class="el-icon-arrow-down"></i> {{ $t.translate("BTN_EXPORT") }}
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-if="$h.hasRoles(['FINANCE', 'ADMIN', 'SUB_ADMIN'])"
                  command="finance"
                  >{{ $t.translate("BTN_EXPORT_FINANCIAL") }}</el-dropdown-item
                >

                <el-dropdown-item
                  v-if="!$h.hasRoles(['FINANCE', 'DEPUTYCEO', 'COO', 'CEO'])"
                  command="application"
                  >{{ $t.translate("BTN_EXPORT_APPLICATION") }}</el-dropdown-item
                >

                <el-dropdown-item
                  v-if="!$h.hasRoles(['FINANCE', 'DEPUTYCEO', 'COO', 'CEO'])"
                  command="application_service"
                  >{{ $t.translate("BTN_EXPORT_APPLICATION_SERVICE") }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-dropdown
            v-if="
              $h.hasRoles([
                'COO',
                'DEPUTYCEO',
                'CEO',
                'ADMIN',
                'SUB_ADMIN',
                'SCHEDULING',
              ]) || userData?.IsGrantGeneration
            "
            class="mb-3"
            trigger="click"
            :hide-on-click="true"
            @command="(comd) => multiple(comd, $refs)"
          >
            <el-button type="primary" style="margin-left: 10px">
              <i class="el-icon-arrow-down"></i> {{ $t.translate("BTN_ACTION") }}
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-if="userData?.IsGrantGeneration" command="publish">{{
                  $t.translate("BTN_PUBLISH")
                }}</el-dropdown-item>

                <el-dropdown-item
                  v-if="$h.hasRoles(['COO', 'DEPUTYCEO', 'CEO', 'ADMIN', 'SUB_ADMIN'])"
                  command="approve"
                  >{{ $t.translate("BTN_APPROVE_APPLICATION") }}</el-dropdown-item
                >

                <el-dropdown-item
                  v-if="$h.hasRoles(['SCHEDULING', 'ADMIN', 'SUB_ADMIN'])"
                  command="assign/assessor"
                  >{{ $t.translate("BTN_ASSIGN_ASSESSOR") }}</el-dropdown-item
                >

                <el-dropdown-item
                  v-if="$h.hasRoles(['SCHEDULING', 'ADMIN', 'SUB_ADMIN'])"
                  command="assign/assessor_onsite"
                  >{{ $t.translate("BTN_ASSIGN_ASSESSOR_ONSITE") }}</el-dropdown-item
                >

                <el-dropdown-item
                  v-if="$h.hasRoles(['SCHEDULING', 'ADMIN', 'SUB_ADMIN'])"
                  command="assign/indepreviewer"
                  >{{ $t.translate("BTN_ASSIGN_IR") }}</el-dropdown-item
                >

                <el-dropdown-item v-if="userData?.IsGrantGeneration" command="generate">{{
                  $t.translate("BTN_GENERATE_CERT_STATEMENT")
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>

        <el-button
          type="primary"
          class="mb-3"
          style="margin-left: 10px"
          v-if="$h.hasRoles(['BUSINESS', 'ADMIN', 'SUB_ADMIN'])"
          @click="clientAdd($refs)"
        >
          {{ $t.translate("BTN_ADD_APPLICATION") }}
        </el-button>
      </div>

      <div class="shadow bg-white p-around">
        <Table
          url="/application/list"
          :query="{ ...Filter }"
          ref="table"
          :default-sort="{ prop: Filter.SortBy, order: Filter.SortOrder }"
          @sort-change="sortChange"
          :viewData="handleEdit"
          class="gen-table-bd"
          @selection-change="handleSelectionChange"
          :height="
            $h.hasRoles(['CLIENT', 'SUB_CLIENT', 'COO', 'DEPUTYCEO', 'CEO', 'SCHEDULING'])
              ? '60vh'
              : '64vh'
          "
        >
          <el-table-column
            type="selection"
            :selectable="selectable"
            width="50"
            v-if="
              $h.hasRoles([
                'COO',
                'DEPUTYCEO',
                'CEO',
                'ADMIN',
                'SUB_ADMIN',
                'SCHEDULING',
              ]) || userData?.IsGrantGeneration
            "
          />

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="ApplicationNo"
            :label="$t.translate('LBL_APPLICATION_NO')"
            min-width="150px"
            class-name="pl-1"
          >
            <template #default="scope">
              {{ scope.row.ApplicationNo }}
              <!-- <div
                style="margin-top: 10px; font-size: 12px; color: red"
                v-if="
                  scope.row.StartDateTime &&
                  scope.row.ApplicationStatusCode == 'INPROGRESS'
                "
              >
                This is shown for uat purposes, original cycle date:
                {{ $h.formatDate(scope.row.StartDateTime) }} -
                {{ $h.formatDate(scope.row.EndDateTime) }}
              </div> -->
            </template>
          </el-table-column>

          <el-table-column :label="$t.translate('FRM_CERT_STAT_NO')" min-width="150px">
            <template #default="scope">
              {{ scope?.row?.CertificateNo || "---" }}
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="AssessmentType"
            :label="$t.translate('FRM_ASSESSMENT_TYPE')"
            width="130px"
          >
            <template #header>
              <TableFilter
                :handleSelection="
                  (arr) => $refs.table.currentSelection(arr, 'AssessmentType')
                "
                :hasSelection="true"
                :fieldName="$t.translate('FRM_ASSESSMENT_TYPE')"
                url="envconf/AccessmentTypeOpts"
                fieldKey="value"
                fieldLabel="label"
              />
            </template>

            <template #default="scope">
              {{ $t.translate(scope.row.AssessmentTypeLabel) }}
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="ServiceName"
            :label="$t.translate('LBL_ASSESSMENT_SERVICE')"
            width="150px"
          >
            <template #header>
              <TableFilter
                :handleSelection="
                  (arr) => $refs.table.currentSelection(arr, 'AssessmentServiceId')
                "
                :hasSelection="true"
                :fieldName="$t.translate('LBL_ASSESSMENT_SERVICE')"
                url="/assessment/list"
                fieldKey="AssessmentServiceId"
                fieldLabel="ServiceCompleteName"
                :fieldLang="true"
              />
            </template>

            <template #default="scope">
              {{ $h.formatName(scope.row, "ServiceName") }}
              <span v-if="$h.formatName(scope.row, 'ServiceNameExtra', true)">
                - {{ $h.formatName(scope.row, "ServiceNameExtra") }}</span
              >
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="Applicant"
            :label="$t.translate('LBL_APPLICANT')"
            width="150px"
          >
            <template #default="scope">
              {{ $h.formatOrgBranch(scope.row) }}
            </template>
          </el-table-column>

          <el-table-column :label="$t.translate('LBL_PAYMENT_STATUS')" width="100px">
            <template #header>
              <TableFilter
                :handleSelection="
                  (arr) => $refs.table.currentSelection(arr, 'PaymentType')
                "
                :hasSelection="true"
                :fieldName="$t.translate('LBL_PAYMENT_STATUS')"
                url="envconf/PaymentOpts"
                fieldKey="value"
                fieldLabel="label"
              />
            </template>

            <template #default="scope">
              <el-tag
                :type="!scope.row.IsPaid ? 'warning' : 'success'"
                effect="dark"
                disable-transitions
                >{{ $t.translate(scope.row.PaymentLabel) }}</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column
            :label="$t.translate('LBL_PAYMENT_DATE')"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="PaymentDateTime"
            width="140px"
          >
            <template #default="scope">
              {{ $h.formatDateTime(scope.row.PaymentDateTime) }}
            </template>
          </el-table-column>

          <template v-if="!$h.hasRoles(['CLIENT', 'SUB_CLIENT'])">
            <el-table-column :label="$t.translate('LBL_ASSESSOR')" width="140px">
              <template #default="scope">
                {{ $h.getFullName(scope.row.AssessorUser, false, "--") }}
              </template>
            </el-table-column>

            <el-table-column :label="$t.translate('LBL_ASSESSOR_ON_SITE')" width="140px">
              <template #default="scope">
                {{ $h.getFullName(scope.row.AssessorOnSiteUser, false, "--") }}
              </template>
            </el-table-column>

            <el-table-column
              :label="$t.translate('LBL_INDEPENDENT_REVIEWER')"
              width="140px"
            >
              <template #default="scope">
                {{ $h.getFullName(scope.row.IndepreviewerUser, false, "--") }}
              </template>
            </el-table-column>
          </template>

          <el-table-column
            :label="$t.translate('LBL_CREATED_DATE')"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="LastCreated"
            width="140px"
          >
            <template #default="scope">
              {{ $h.formatDateTime(scope.row.LastCreated) }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t.translate('LBL_LAST_UPDATE')"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="LastModified"
            width="140px"
          >
            <template #default="scope">
              {{ $h.formatDateTime(scope.row.LastModified) }}
            </template>
          </el-table-column>

          <el-table-column
            v-if="!$h.hasRoles(['CLIENT', 'SUB_CLIENT'])"
            :label="$t.translate('LBL_PROGESS')"
            :fixed="mq.current == 'xsmall' ? false : 'right'"
            width="330"
          >
            <template #header>
              <TableFilter
                :handleSelection="(arr) => $refs.table.currentSelection(arr, 'Progress')"
                :hasSelection="true"
                :fieldName="$t.translate('LBL_PROGESS')"
                url="envconf/ApplicationProgressOpts"
                fieldKey="value"
                fieldLabel="label"
              />
            </template>

            <template #default="scope">
              <ApplicationProgress :data="scope.row" />
            </template>
          </el-table-column>

          <el-table-column
            :label="$t.translate('LBL_STATUS')"
            :fixed="mq.current == 'xsmall' ? false : 'right'"
            width="132px"
          >
            <template #header>
              <TableFilter
                :handleSelection="
                  (arr) => $refs.table.currentSelection(arr, 'ApplicationStatusCode')
                "
                :hasSelection="true"
                :fieldName="$t.translate('LBL_STATUS')"
                url="envconf/ApplicationStatusOpts"
                fieldKey="value"
                fieldLabel="label"
              />
            </template>

            <template #default="scope">
              <el-tag
                :type="applicationStatusStyle[scope.row.ApplicationStatusCode]"
                effect="dark"
                disable-transitions
                >{{ $t.translate(scope.row.ApplicationStatusName) }}</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column
            fixed="right"
            :label="$t.translate('LBL_ACTION')"
            width="70"
            class-name="pl-1 action-col"
          >
            <template #default="scope">
              <DropDown
                :data="scope.row"
                @selected="
                  (comd, application) => $f.status(application, comd, false, $refs)
                "
              />
            </template>
          </el-table-column>
        </Table>
      </div>
    </div>
  </div>

  <ApplicationModal ref="select" />
  <ExportModal :types="['PDF', 'EXCEL']" ref="export" />
</template>
<script>
import ApplicationModal from "@/components/ApplicationModal";
import ExportModal from "@/components/ExportModal";
import ApplicationProgress from "@/components/ApplicationProgress";
import DropDown from "@/components/DropDown";
import Table from "@/components/Table.vue";
import TableFilter from "@/components/TableFilter.vue";
import { reactive, onMounted, ref, onUpdated } from "vue";
import { get, post, isValidResponse } from "@/utils/axios";
import { router } from "@/utils/router";
import { helpers } from "@/utils/helpers";
import { useRoute } from "vue-router";
import _ from "lodash";
import { useMq } from "vue3-mq";
import { extendedConfigs } from "@/utils/configs";
import { store } from "@/store";
import { ElMessageBox } from "element-plus";
import { translation } from "@/utils/translation";

export default {
  components: {
    Table,
    TableFilter,
    ApplicationModal,
    ExportModal,
    ApplicationProgress,
    DropDown,
  },
  setup() {
    let { applicationStatusStyle } = extendedConfigs;
    const mq = useMq();
    const route = useRoute();
    let loaded = ref(false);
    let table = ref("table");

    let userData = store.getters["user/getData"]?.UserData;

    let Options = ref([]);
    let fieldsToSkip = ["SortBy", "SortOrder", "ApplicationStatusCode"];
    let Filter = reactive({
      ApplicationStatusCode: route?.query?.status || "",
      ApplicationNo: "",
      Applicant: "",
      Assessor: "",
      IndependentReviewer: "",
      DatePeriod: [],
      SortBy: "LastModified",
      SortOrder: "descending",
    });
    let selectedRows = ref([]);
    let selectedRowsForCert = ref([]);

    onUpdated(() => {
      let filter =
        route?.query?.status && route?.query?.status != "ALL" ? route.query.status : "";
      if (filter == Filter.ApplicationStatusCode) return;
      Filter.ApplicationStatusCode = filter; //pass
      table.value.handleCurrentChange(1, Filter);
    });

    let filterAction = async () => {
      await table.value.handleCurrentChange(1, Filter);
    };

    const addCase = async () => {
      router.changeRoute("/application/add");
    };

    const clientAdd = async ($refs) => {
      let data = await $refs.select.clientAdd();
      if (data) {
        let res = await post("/application/create", data);
        if (isValidResponse(res)) {
          router.changeRoute("/application/edit", { key: res.ApplicationKey });
        }
      }
    };

    const multiple = async (mode, $refs) => {
      let maxGenerateCert = 5;

      if (!selectedRows.value.length) {
        return helpers.showWarning({
          Code: "ERR_NO_ITEM_SELECTED",
          Message: "ERR_NO_ITEM_SELECTED",
        });
      }

      let body = {
        List: _.map(selectedRows.value, (r) => r.ApplicationKey),
        CertificateKey: _.uniq(
          _.map(
            _.filter(selectedRows.value, (r) => r.CertificateKey),
            (r) => r.CertificateKey
          )
        ),
      };

      if (mode.indexOf("assign") > -1) {
        let RoleCode = mode.replace("assign/", "").toUpperCase();
        $refs.select.assign(null, "ASSESSOR", async (AssignedUserKey) => {
          let res = await post("/application/multiple/assign", {
            ...body,
            RoleCode,
            AssignedUserKey,
          });
          if (isValidResponse(res)) {
            await table.value.clearSelection();
            await table.value.handleCurrentChange(null, Filter);
            helpers.showMessage({
              Code: "MSG_SUCCESS",
              Message: "MSG_ASSIGNED_SUCCESSFULLY",
            });
          }
        });
      } else if (mode == "approve") {
        $refs.select.reject(async (Remark, Attachment) => {
          let res = await post("/application/multiple/approve", {
            ...body,
            Remark,
            Attachment,
          });
          if (isValidResponse(res)) {
            await table.value.clearSelection();
            await table.value.handleCurrentChange(null, Filter);
            helpers.showMessage({
              Code: "MSG_SUCCESS",
              Message: "MSG_REQUEST_SUCCESS",
            });
          }
        });
      } else if (mode == "publish") {
        $refs.select.reject(async (Remark, Attachment) => {
          let res = await post("/application/publish", {
            ApplicationKey: _.map(selectedRows.value, (r) => r.ApplicationKey),
            Remark,
            Attachment,
          });
          if (isValidResponse(res)) {
            await table.value.clearSelection();
            await table.value.handleCurrentChange(null, Filter);
            helpers.showMessage({
              Code: "MSG_SUCCESS",
              Message: "MSG_REQUEST_SUCCESS",
            });
          }
        });
      } else if (mode == "generate") {
        if (body.CertificateKey.length > maxGenerateCert) {
          let msg = translation
            .translate("ERR_MAX_CERTIFICATE")
            .replace("{COUNT}", maxGenerateCert);
          return helpers.showWarning({
            Code: msg,
            Message: msg,
          });
        }

        await ElMessageBox.confirm(
          translation.translate("MSG_WARNING_CONFIRM_DELETE"),
          translation.translate("TTL_WARNING"),
          {
            confirmButtonText: translation.translate("BTN_OKAY"),
            cancelButtonText: translation.translate("BTN_CANCEL"),
            type: "warning",
          }
        );
        let res = await post("/application/certificate/regenerate", { ...body });
        if (isValidResponse(res)) {
          await table.value.clearSelection();
          await table.value.handleCurrentChange(null, Filter);
          helpers.showMessage({
            Code: "MSG_SUCCESS",
            Message: "MSG_REQUEST_SUCCESS",
          });
        }
      }
    };

    onMounted(async () => {
      if (route?.query?.status && route?.query?.status != "ALL") {
        Filter.ApplicationStatusCode = route.query.status;
      } else {
        Filter.ApplicationStatusCode = "";
      }

      loaded.value = true;
    });

    const resetParams = () => {
      let keys = Object.keys(Filter);
      for (let key of keys) {
        if (fieldsToSkip.indexOf(key) > -1) continue;
        Filter[key] = "";
      }
      filterAction();
    };

    const sortChange = ({ prop, order }) => {
      Filter.SortBy = prop;
      Filter.SortOrder = order;
      filterAction();
    };

    const handleEdit = (row, opt) => {
      helpers.handleViewApplication(router, row, opt);
    };

    const handleSelectionChange = (arr) => {
      selectedRows.value = arr;
    };

    const exportCmd = (cmd, $refs) => {
      let FilterTable = { ...Filter, Filter: $refs?.table?.Filter?.Filter || "" };

      if (cmd == "finance") {
        $refs.export.exportContent({
          Mode: "GENERATE_FINANCIAL",
          ExcelOnly: true,
        });
      } else if (cmd == "application") {
        $refs.export.exportContent({
          Mode: "GENERATE_APPLICATION",
          Filter: FilterTable,
          ExcelOnly: true,
        });
      } else if (cmd == "application_service") {
        $refs.export.exportContent({
          Mode: "GENERATE_APPLICATION_SERVICE",
          Filter: FilterTable,
          ExcelOnly: true,
        });
      }
    };

    const selectable = (row) => {
      return ["INPROGRESS", "WAITING"].includes(row.ApplicationStatusCode) ? false : true;
    };

    return {
      selectable,
      clientAdd,
      exportCmd,
      applicationStatusStyle,
      mq,
      filterAction,
      sortChange,
      loaded,
      table,
      userData,
      Filter,
      selectedRows,
      selectedRowsForCert,
      Options,
      resetParams,
      addCase,
      handleEdit,
      handleSelectionChange,
      multiple,
    };
  },
};
</script>
<style></style>
