<template>
  <modal v-model:show="showConsentModal" class="consent-modal" :showClose="false">
    <template #header>
      <h2 id="cookiesModalLabel" class="modal-title f-text-blue">
        {{ $t.translate("TTL_CONSENT") }}
      </h2>
    </template>
    <div class="disc-content mt--1">
      <Cookies :key="`currentlang-${$t.getLanguage()}`" />
    </div>
    <template #footer>
      <el-button type="info" class="f-btn-blue" @click="dismissModal">{{
        $t.translate("BTN_ACCEPT_NECE_COOKIES")
      }}</el-button>

      <el-button type="primary" class="ml-0 mt-3 mt-md-0" @click="dismissModal">{{
        $t.translate("BTN_ACCEPT_ALL_COOKIES")
      }}</el-button>
    </template>
  </modal>

  <div class="upper-main-cont">
    <router-view v-if="loaded" />
    <notifications position="top right" :max="1" />
  </div>
</template>

<script>
import Cookies from "@/components/base/Cookies";
import { defineComponent, onMounted, computed, onUpdated, ref } from "vue";
import { store } from "@/store";
import { helpers } from "@/utils/helpers";
import { useRoute } from "vue-router";
import { configs, extendedConfigs } from "@/utils/configs";
import { emitter } from "@/utils/emitter";

let timer = null;

export default defineComponent({
  name: "MainApp",
  components: {
    Cookies,
  },
  setup() {
    const route = useRoute();
    let loaded = ref(false);
    let showModal = ref(false);

    let showConsentModal = computed(() => {
      return route.path != "/privacy" && showModal.value;
    });

    let logout = async () => {
      let userToken = store.getters["user/getToken"];
      if (userToken && userToken !== "") {
        await store.dispatch("user/resetData");
        window.location.href = "/login";
      }
    };

    let resetTimer = () => {
      if (timer) clearTimeout(timer);
      const userToken = store.getters["user/getToken"];

      if (userToken && userToken !== "") {
        timer = setTimeout(logout, 900000); // 15 minutes
      }
    };

    const dismissModal = async () => {
      showModal.value = false;
      await store.dispatch("consent/setData", { IsCookieConsent: true });
    };

    onMounted(async () => {
      //window.onload = resetTimer;
      //document.onmousemove = resetTimer;
      //document.onkeypress = resetTimer;
      //window.addEventListener("hkqaa-authed", resetTimer, false);

      window.addEventListener(
        "message",
        (e) => {
          emitter.emit("iframe-message", e);
        },
        false
      );

      await store.dispatch("translation/fetchData");
      await store.dispatch("user/fetchData");
      await store.dispatch("page/resetData");
      await store.dispatch("config/fetchData");

      if (route?.query?.lang) {
        let found = _.find(extendedConfigs.langList, (r) => {
          return r.altCode == route.query.lang;
        });

        if (found) {
          await store.dispatch("translation/setData", { languageCode: found.code });
        }
      }

      //check for T&C
      await helpers.checkForTC();

      //check for Cookies
      let showCookies = await helpers.checkToCookies();
      if (showCookies) {
        showModal.value = true;
      }

      //add custom script here
      let script = document.createElement("script");
      script.src = configs.mpgsURL;
      document.head.appendChild(script);

      loaded.value = true;
    });

    return { loaded, showConsentModal, dismissModal };
  },
});
</script>

<style type="text/css">
.vue-notification-template {
  margin-top: 30px;
}

.consent-modal .modal-header {
  border-bottom: 1.5px solid #e9ecef;
}
.consent-modal .modal-footer {
  justify-content: space-between;
  border-top: 1.5px solid #e9ecef;
}
</style>
