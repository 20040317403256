<template>
  <el-dialog
    v-model="paymentDialogVisible"
    :title="$t.translate('TTL_PAYMENT')"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :custom-class="paymentDialogClass"
  >
    <div v-loading="true" v-if="!paymentObject" style="min-height: 300px"></div>

    <section v-if="paymentObject" class="payment-container">
      <h2>
        {{
          $t.translate(
            paymentObject?.PaymentData.IsEmbeded ? "TTL_PAYMENT" : "TTL_SCAN_QR_CODE"
          )
        }}
      </h2>
      <p>
        {{
          $t.translate(
            paymentObject?.PaymentData.IsEmbeded
              ? "TTL_ENTER_DETAILS_TO_PAY"
              : "TTL_SCAN_QR_CODE_TO_PAY"
          )
        }}
      </p>

      <div
        v-if="paymentObject?.PaymentData?.code_img_url"
        class="qr-code-image"
        :style="{
          'background-image': `url('${paymentObject.PaymentData.code_img_url}')`,
        }"
      ></div>

      <div
        v-if="paymentObject?.PaymentData?.IsShowFrame"
        v-loading="paymentObject.loading"
        class="iframe-loader"
      >
        <iframe
          class="payment-iframe-qr"
          :src="paymentObject?.PaymentData?.pay_url"
          @load="paymentObject.loading = false"
        >
        </iframe>
      </div>

      <div
        v-if="paymentObject?.PaymentData.IsEmbeded"
        id="embed-payment-mpgs"
        style="width: 100%"
      ></div>

      <p>
        {{ $t.translate("LBL_APPLICATION_NO") }}:
        {{ paymentObject?.InvoiceType?.Description?.ApplicationNo }}
      </p>

      <div class="payment-method static">
        <div
          :style="{
            'background-image': `url('/img/payment/${paymentObject?.PaymentMethod?.logo}')`,
          }"
        ></div>
        <div
          v-if="paymentObject?.PaymentMethod?.altlogo"
          :style="{
            'background-image': `url('/img/payment/${paymentObject?.PaymentMethod?.altlogo}')`,
          }"
        ></div>
        {{ $t.translate(paymentObject?.PaymentMethod.label) }}
      </div>

      <el-button v-if="!paymentObject?.IsSuccess" @click="cancelPayment(true)">{{
        $t.translate("BTN_CANCEL")
      }}</el-button>
      <el-button
        type="primary"
        v-if="paymentObject?.IsSuccess"
        @click="cancelPayment(true)"
        >{{ $t.translate("BTN_CONFIRM") }}</el-button
      >
    </section>
  </el-dialog>

  <el-dialog
    v-model="rejectDialogVisible"
    :title="$t.translate(dialogTitle)"
    custom-class="cus-cont"
  >
    <div class="mb-3 mt-3" v-if="enableOnlinePayment && currentAction == 'PAY_NOW'">
      <!-- <label for="">{{ $t.translate("TTL_PAYMENT_METHOD") }}</label> -->

      <div>
        <el-radio-group v-model="paymentMethod">
          <el-radio class="mr-3" :label="0">
            {{ $t.translate("LBL_PAY_ONLINE") }}
          </el-radio>
          <el-radio class="mr-3" :label="1">
            {{ $t.translate("LBL_PAY_OFFLINE") }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>

    <el-alert
      v-if="message && currentAction == 'PAY_NOW' && paymentMethod == 1"
      :title="$t.translate(message)"
      type="warning"
      show-icon
      style="margin-bottom: 10px"
      :closable="false"
    ></el-alert>

    <div class="row justify-content-between" v-if="image">
      <div class="col-md-6">
        <p>{{ $t.translate("LBL_PAYMENT_PROOF") }}</p>
      </div>
      <div class="col-md-6 text-md-right">
        <a
          class="el-button el-button--text"
          :href="$axios.formulateUrl('/export/download?Token=' + image)"
        >
          <i class="c-pointer fas fa-eye"></i>
        </a>
      </div>
    </div>

    <template v-if="['CONFIRM_PAYMENT', 'TOPAID'].includes(currentAction)">
      <div class="mb-3 mt-3">
        <label for="">{{ $t.translate("LBL_PAYMENT_DATE_BY_CLIENT") }}:</label><br />
        {{ $h.formatDate(currentData.SettlementDateTime) }}
      </div>
    </template>

    <template v-if="ableToConvert">
      <el-select
        :placeholder="$t.translate('FRM_SELECT')"
        v-model="specialType"
        class="form-group w-100"
        :class="{ 'inp-invalid': $h.isBlank(specialType) }"
      >
        <el-option
          :key="1"
          :label="$t.translate('LBL_NEED_TO_CHANGE_CERTIFICATE')"
          :value="1"
        />

        <el-option
          :key="0"
          :label="$t.translate('LBL_NO_NEED_TO_CHANGE_CERTIFICATE')"
          :value="0"
        />
      </el-select>
    </template>

    <template
      v-if="
        (currentAction == 'PAY_NOW' && paymentMethod == 1) ||
        ['TOPAID', 'CONFIRM_PAYMENT'].includes(currentAction)
      "
    >
      <div class="mb-3 mt-3">
        <label for="">{{ $t.translate("TTL_PAYMENT_DATE") }}</label>
        <el-date-picker
          class="w-100"
          v-model="paymentDate"
          type="date"
          :class="{ 'inp-invalid': $h.isBlank(paymentDate) }"
          :disabled-date="disabledDate"
          value-format="YYYY-MM-DD"
          :placeholder="$t.translate('TTL_PAYMENT_DATE')"
        />
      </div>
    </template>

    <label v-if="isRequiredTranx" for="">{{ $t.translate("FRM_TRANX_NO") }}</label>

    <el-input
      v-if="
        (currentAction == 'PAY_NOW' && paymentMethod == 1) || currentAction != 'PAY_NOW'
      "
      v-model="reason"
      :rows="8"
      :type="isRequiredTranx ? 'text' : 'textarea'"
      style="margin-bottom: 10px"
      :placeholder="$t.translate(isRequiredTranx ? 'FRM_TRANX_NO' : 'TTL_INPUT_REMARK')"
      :class="{
        'inp-invalid': $h.isBlank(reason) && (isRequiredReason || isRequiredTranx),
      }"
    />

    <template
      v-if="
        (canUpload && currentAction == 'PAY_NOW' && paymentMethod == 1) ||
        (canUpload && currentAction != 'PAY_NOW')
      "
    >
      <span class="pre-line mb-0">{{ $t.translate("LBL_UPLOAD_FILE_LIMIT_CRB") }}</span>
      <span class="pre-line"
        >{{
          $t.swapValues(
            {
              SPECIAL_CHARS: $h.getSpecialChars(),
            },
            $t.translate("LBL_UPLOAD_FILE_NAME_LIMIT")
          )
        }}
      </span>

      <div
        v-if="reasonAttachment"
        style="margin-top: 10px"
        class="el-alert el-alert--success is-light"
        role="alert"
      >
        <i class="el-alert__icon el-icon-success"></i>
        <div class="el-alert__content">
          <span class="el-alert__title">{{ reasonAttachmentName }}</span>
          <i
            class="el-alert__closebtn el-icon-close"
            @click="reasonAttachment = null"
          ></i>
        </div>
      </div>

      <el-upload
        style="margin-top: 10px"
        :show-file-list="false"
        :action="$axios.formulateUrl('/media/upload/tmp?general=1')"
        :file-list="[]"
        :headers="$axios.getHeaders()"
        :on-error="$axios.uploadError"
        :on-progress="$axios.uploadProgress"
        :on-success="(res, file) => sucUploadDocu(res, file, $axios.doneUpload)"
        :accept="$h.getCRBFileTypes()"
      >
        <el-button type="primary">{{ $t.translate("BTN_UPLOAD_DOCUMENT") }}</el-button>
      </el-upload>
    </template>

    <template v-if="currentAction == 'PAY_NOW' && paymentMethod == 0">
      <div class="mb-3 mt-3">
        <label for="">{{ $t.translate("TTL_PAYMENT_METHOD") }}:</label>
        <template v-for="(item, index) in paymentMethods" :key="index">
          <div
            v-if="item.IsEnabled == 1"
            class="payment-method"
            @click="selectPayType(item)"
            :class="{ selected: item.PayType == payType, disabled: !item.IsEnabled }"
          >
            <div
              :style="{ 'background-image': `url('/img/payment/${item.logo}')` }"
            ></div>

            <div
              v-if="item?.altlogo"
              :style="{
                'background-image': `url('/img/payment/${item.altlogo}')`,
              }"
            ></div>

            {{ $t.translate(item.label) }}
          </div>
        </template>
      </div>
    </template>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="rejectDialogVisible = false">{{
          $t.translate("BTN_CANCEL")
        }}</el-button>
        <el-button
          type="primary"
          :disabled="currentAction == 'PAY_NOW' && !payType && paymentMethod == 0"
          @click="confirmReject"
          >{{ $t.translate(buttonName) }}</el-button
        >
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="crbForm.Dialog"
    :title="$t.translate('TTL_CONFIRM_ACTION')"
    custom-class="cus-cont"
  >
    <div class="mb-3 mt-3">
      <label for="">{{ $t.translate("LBL_CERTIFICATE_DATE") }}</label>
      <el-date-picker
        :disabled-date="disabledDate"
        class="w-100"
        v-model="crbForm.Form.CertificateDate"
        type="date"
        :class="{ 'inp-invalid': $h.isBlank(crbForm.Form.CertificateDate) }"
        value-format="YYYY-MM-DD"
        :placeholder="$t.translate('LBL_CERTIFICATE_DATE')"
      />
    </div>

    <div class="mb-3 mt-3">
      <label for="">{{ $t.translate("LBL_CERT_NO_CRB") }}</label>
      <el-input
        v-model="crbForm.Form.CertificateNo"
        :placeholder="$t.translate('LBL_CERT_NO_CRB')"
        :class="{
          'inp-invalid': $h.isBlank(crbForm.Form.CertificateNo),
        }"
      >
        <template #prepend>{{ crbForm.Form.CertificatePrefix }}</template>
      </el-input>
    </div>

    <span class="pre-line mb-0">{{ $t.translate("LBL_UPLOAD_FILE_LIMIT_CRB") }}</span>
    <span class="pre-line"
      >{{
        $t.swapValues(
          {
            SPECIAL_CHARS: $h.getSpecialChars(),
          },
          $t.translate("LBL_UPLOAD_FILE_NAME_LIMIT")
        )
      }}
    </span>

    <div class="mb-3 mt-3">
      <label for="">{{ $t.translate("LBL_RELEVANT_SUP_DOCU") }}</label>
      <el-alert
        v-if="crbForm.Form.SupportingFile"
        :title="crbForm.Form.SupportingFile"
        type="success"
        show-icon
        style="margin-bottom: 10px"
        :closable="false"
      ></el-alert>

      <el-upload
        style="margin-top: 10px"
        :show-file-list="false"
        :action="$axios.formulateUrl('/media/upload/tmp?general=1')"
        :file-list="[]"
        :headers="$axios.getHeaders()"
        :on-error="$axios.uploadError"
        :on-progress="$axios.uploadProgress"
        :on-success="
          (res, file) => sucUploadCRB(res, file, 'SupportingFile', $axios.doneUpload)
        "
        :accept="$h.getCRBFileTypes()"
      >
        <el-button type="primary">{{ $t.translate("BTN_UPLOAD") }}</el-button>
      </el-upload>
    </div>

    <!-- 

    <div class="mb-3 mt-3">
      <label for="">{{ $t.translate("LBL_UPLOAD_CERTIFICATE") }}</label>
      <el-alert
        v-if="crbForm.Form.CertificateFile"
        :title="crbForm.Form.CertificateFile"
        type="success"
        show-icon
        style="margin-bottom: 10px"
        :closable="false"
      ></el-alert>

      <el-upload
        style="margin-top: 10px"
        :show-file-list="false"
        :action="$axios.formulateUrl('/media/upload/tmp?general=1')"
        :file-list="[]"
        :headers="$axios.getHeaders()"
        :on-error="$axios.uploadError"
        :on-progress="$axios.uploadProgress"
        :on-success="
          (res, file) => sucUploadCRB(res, file, 'CertificateFile', $axios.doneUpload)
        "
        :accept="$h.getCRBFileTypes()"
      >
        <el-button type="primary">{{ $t.translate("BTN_UPLOAD") }}</el-button>
      </el-upload>
    </div>

    <div class="mb-3 mt-3">
      <label for="">{{ $t.translate("LBL_DISCLOSURE_DOCUMENT") }}</label>
      <div>
        <el-radio-group
          @change="crbForm.Form.DisclosureFile = null"
          v-model="crbForm.Form.IsDisclosureUpload"
        >
          <el-radio class="mr-3" :label="0">
            {{ $t.translate("FRM_GENERATE_FROM_PLATFORM") }}
          </el-radio>
          <el-radio class="mr-3" :label="1">
            {{ $t.translate("FRM_UPLOAD") }}
          </el-radio>
        </el-radio-group>
      </div>

      <el-alert
        v-if="crbForm.Form.DisclosureFile"
        :title="crbForm.Form.DisclosureFile"
        type="success"
        show-icon
        style="margin-bottom: 10px"
        :closable="false"
      ></el-alert>

      <el-upload
        v-if="crbForm.Form.IsDisclosureUpload == 1"
        style="margin-top: 10px"
        :show-file-list="false"
        :action="$axios.formulateUrl('/media/upload/tmp?general=1')"
        :file-list="[]"
        :headers="$axios.getHeaders()"
        :on-error="$axios.uploadError"
        :on-progress="$axios.uploadProgress"
        :on-success="
          (res, file) => sucUploadCRB(res, file, 'DisclosureFile', $axios.doneUpload)
        "
        :accept="$h.getCRBFileTypes()"
      >
        <el-button type="primary">{{ $t.translate("BTN_UPLOAD") }}</el-button>
      </el-upload>
    </div> -->

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="crbForm.Dialog = false">{{
          $t.translate("BTN_CANCEL")
        }}</el-button>
        <el-button type="primary" @click="confirmCRB">{{
          $t.translate("BTN_CONFIRM")
        }}</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="assignDialog"
    :title="$t.translate('TTL_CONFIRM_ACTION')"
    custom-class="cus-cont"
  >
    <p>{{ $t.translate("LBL_ASSIGN_STAFF_GENERAL") }}</p>

    <template v-if="formData?.ApplicationNo || formData?.CertificateNo">
      <div v-if="formData.ApplicationNo">
        <p class="form-control-label no-margin">
          {{ $t.translate("LBL_APPLICATION_NO") }}
        </p>
        <p>{{ formData.ApplicationNo }}</p>
      </div>

      <div v-if="formData.CertificateNo">
        <p class="form-control-label no-margin">{{ $t.translate("FRM_CERT_STAT_NO") }}</p>
        <p>{{ formData.CertificateNo }}</p>
      </div>

      <p class="form-control-label no-margin">
        {{ $t.translate("FRM_ASSESSMENT_SERVICE_NAME") }}
      </p>
      <p>
        {{ $h.formatName(formData, "ServiceName")
        }}<span v-if="$h.formatName(formData, 'ServiceNameExtra', true)">
          - {{ $h.formatName(formData, "ServiceNameExtra") }}</span
        >
      </p>
    </template>
    <el-select
      v-model="assigned"
      :placeholder="$t.translate('LBL_TYPE_TO_SEARCH')"
      filterable
      remote
      reserve-keyword
      :remote-method="searchStaff"
      class="form-group w-100"
      :class="{ 'inp-invalid': $h.isBlank(assigned) }"
    >
      <el-option
        v-for="item in staffsOptions"
        :key="item.UserKey"
        :label="$h.getFullName(item)"
        :value="item.UserKey"
      />
    </el-select>

    <el-input
      v-model="reason"
      :rows="3"
      type="textarea"
      style="margin-bottom: 10px"
      :placeholder="$t.translate('TTL_INPUT_REMARK')"
    />

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="assignDialog = false">{{
          $t.translate("BTN_CANCEL")
        }}</el-button>
        <el-button type="primary" @click="confirmAssign">{{
          $t.translate("BTN_CONFIRM")
        }}</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="createDialog"
    :title="$t.translate('TTL_CREATE_AN_APPLICATION_FOR_CLIENT')"
    :before-close="handleCreateClose"
    custom-class="cus-cont"
  >
    <p>{{ $t.translate("LBL_SERVICE") }}</p>
    <el-select
      v-model="createForm.ServiceKey"
      class="form-group w-100"
      :class="{ 'inp-invalid': $h.isBlank(createForm.ServiceKey) }"
    >
      <el-option
        v-for="item in serviceOptions"
        :key="item.ServiceKey"
        :label="$h.formatServiceName(item)"
        :value="item.ServiceKey"
      />
    </el-select>

    <p>{{ $t.translate("LBL_HANDLED_CLIENT") }}</p>
    <el-select
      v-model="createForm.UserKey"
      :placeholder="$t.translate('LBL_TYPE_TO_SEARCH')"
      filterable
      remote
      reserve-keyword
      :remote-method="searchClient"
      class="form-group w-100"
      :class="{ 'inp-invalid': $h.isBlank(createForm.UserKey) }"
    >
      <el-option
        v-for="item in clientOptions"
        :key="item.UserKey"
        :label="$h.formatOrgBranch(item) || $h.getFullName(item) || item.ClientNo"
        :value="item.UserKey"
      />
    </el-select>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCreateClose(null)">{{
          $t.translate("BTN_CANCEL")
        }}</el-button>
        <el-button type="primary" @click="confirmCreate">{{
          $t.translate("BTN_CONFIRM")
        }}</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="inputDialogVisible"
    :title="$t.translate('TTL_INPUT')"
    custom-class="cus-cont"
  >
    <p>
      {{ $t.translate("LBL_APPLICATION_NO") }}:
      {{ currentData?.ApplicationNo }}
    </p>
    <p>
      {{ $t.translate("LBL_CURRENT_MACHFORM_ENTRY_NO") }}:
      {{ currentData?.MachformEntryKey || "---" }}
    </p>

    <label for="">{{ $t.translate("FRM_MACHFORM_ENTRY_NO") }}</label>
    <el-input
      v-model="inputData"
      :placeholder="$t.translate('PLH_ADD_CONTENT_HERE')"
      :class="{
        'inp-invalid': $h.isBlank(inputData),
      }"
    />

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="inputDialogVisible = false">{{
          $t.translate("BTN_CANCEL")
        }}</el-button>
        <el-button type="primary" @click="confirmInput">{{
          $t.translate("BTN_CONFIRM")
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { formulateUrl, get, post, isValidResponse } from "@/utils/axios";
import { store } from "@/store";
import dayjs from "dayjs";
import { extendedConfigs } from "@/utils/configs";
import { helpers } from "@/utils/helpers";

export default {
  data() {
    return {
      inputDialogVisible: false,
      inputData: null,

      paymentDialogVisible: false,
      paymentDialogClass: "cus-cont",
      gettingPaymentData: false,
      paymentObject: null,
      paymentTimeout: null,
      paymentCallBack: null,

      crbForm: {
        Dialog: false,
        Resolve: null,
        Form: {},
      },

      createDialog: false,
      createForm: {
        Resolve: null,
        ServiceKey: null,
        UserKey: null,
      },

      paymentDate: null,
      currentAction: null,
      specialType: 1,
      image: null,
      canUpload: false,
      dialogTitle: "TTL_CONFIRM_ACTION",
      buttonName: "BTN_CONFIRM",
      message: null,
      clientOptions: [],
      staffsOptions: [],
      serviceOptions: [],
      userRole: null,
      ableToConvert: false,
      formData: null,
      assignDialog: false,
      assigned: null,
      reason: null,
      isRequiredReason: false,
      isRequiredTranx: false,
      attachmentName: null,
      reasonAttachment: null,
      cb: null,
      rejectDialogVisible: false,
      paymentMethod: 0,
      payType: null,
      paymentMethods: [],
    };
  },
  computed: {
    reasonAttachmentName() {
      if (!this.attachmentName) return "";
      return this.attachmentName;
    },
    isHaveAmount() {
      if (this.currentData) {
        let ApplicationFee = this.currentData?.ApplicationFee || 0;
        let AssessmentFee = this.currentData?.AssessmentFee || 0;
        let Total = ApplicationFee + AssessmentFee;
        if (Total > 0) return true;
      }
      return false;
    },
    enableOnlinePayment() {
      let { IsAllowOnlinePayment } = store.getters["config/getData"]?.envConf;
      return this.isHaveAmount &&
        IsAllowOnlinePayment &&
        this.paymentMethods.filter((x) => x.IsEnabled).length
        ? true
        : false;
    },
  },
  methods: {
    setPayment(paymentData) {
      //ensure to clear first
      sessionStorage.removeItem("HostedCheckout_sessionId");

      Checkout.configure({
        session: {
          id: paymentData.session.id,
        },
      });

      setTimeout(() => {
        if (paymentData?.IsDirect) {
          Checkout.showPaymentPage();
        } else {
          Checkout.showEmbeddedPage("#embed-payment-mpgs");
        }
      }, 100);
    },
    async checkStatus() {
      if (this?.paymentObject?.PaymentData?.statusUrl) {
        let url = this.paymentObject.PaymentData.statusUrl.split("/api");
        url = url[url.length - 1];

        let res = await post(url, this.paymentObject.PaymentData.statusBody, true, false);
        if (res?.IsProcessing) {
          //call again
          this.paymentTimeout = setTimeout(() => {
            this.checkStatus();
          }, helpers.randomIntFromInterval(3, 5) * 1000);
        } else {
          this.checkPaymentData(res);
        }
      }
    },
    reloadNow() {
      this.paymentDialogVisible = false;
      this.paymentObject = null;
      this.paymentCallBack();
    },
    checkPaymentData(res) {
      if (this.paymentTimeout) {
        clearTimeout(this.paymentTimeout);
      }

      this.paymentObject.IsSuccess = res?.IsPaymentSuccess ? true : false;
      if (!this?.paymentObject?.PaymentData?.IsEmbeded) {
        this.cancelPayment();
      }
    },
    cancelPayment(instant = false) {
      if (this.paymentTimeout) {
        clearTimeout(this.paymentTimeout);
      }

      if (instant) {
        return this.reloadNow();
      }

      setTimeout(() => {
        this.reloadNow();
      }, 2000);
    },
    async showPaymentNow(InvoiceKey, cb) {
      this.paymentDialogClass = "cus-cont";
      this.paymentDialogVisible = true;
      this.gettingPaymentData = true;
      this.paymentCallBack = cb;

      let res = await post("/invoice/pay", { InvoiceKey });
      if (res?.PaymentData?.code_img_url) {
        this.paymentObject = res;
        this.gettingPaymentData = false;
        this.checkStatus();
        return;
      } else if (res?.PaymentData?.IsShowFrame) {
        this.paymentObject = { ...res, loading: true };
        this.gettingPaymentData = false;
        this.checkStatus();
        return;
      } else if (res?.PaymentData?.IsEmbeded) {
        this.paymentDialogClass = "middle-cont";
        this.paymentObject = res;
        this.gettingPaymentData = false;
        this.setPayment(res.PaymentData);
        if (!res?.PaymentData?.IsDirect) {
          this.checkStatus();
        }
        return;
      } else if (res?.PaymentData?.pay_url) {
        window.location.href = res.PaymentData.pay_url;
        return;
      }

      this.paymentDialogVisible = false;
    },
    disabledDate(d) {
      return dayjs(d) > dayjs().endOf("day");
    },
    async searchService() {
      let res = await get(
        "/assessment/list",
        {
          page: 1,
          size: 999,
          IsActive: 1,
        },
        true
      );

      this.serviceOptions = res?.data || [];
    },
    async searchClient(query) {
      let res = await get(
        "/user/list",
        {
          page: 1,
          size: 999,
          UserStatusCode: "ACTIVE",
          RoleCode: "CLIENT",
          Keyword: query || "",
          IsComplete: 1,
          SortBy: "OrgBranch",
          SortOrder: "ASC",
        },
        true
      );
      this.clientOptions = res?.data || [];
    },
    async searchStaff(query) {
      let res = await get(
        "/user/list",
        {
          page: 1,
          size: 999,
          ApplicationKey: this?.formData?.ApplicationKey || "",
          AssessmentServiceKey: this?.formData?.AssessmentServiceKey || "",
          RoleCode: this.userRole,
          Keyword: query || "",
        },
        true
      );
      this.staffsOptions = res?.data || [];
    },
    confirmAssign() {
      if (this.cb) this.cb(this.assigned, this.reason);
      this.assignDialog = false;
    },
    handleCreateClose(done) {
      if (this.createForm.Resolve) this.createForm.Resolve(null);
      if (done) done();
      if (!done) this.createDialog = false;
    },
    confirmCreate() {
      if (!this.createForm.UserKey || !this.createForm.ServiceKey) {
        this.$message.error(this.$t.translate("LBL_REQUIRED_FIELD"));
        return;
      }

      this.createForm.Resolve({
        UserKey: this.createForm.UserKey,
        AssessmentServiceKey: this.createForm.ServiceKey,
      });
      this.createDialog = false;
    },
    async clientAdd() {
      return new Promise((resolve, reject) => {
        this.createForm.ServiceKey = null;
        this.createForm.UserKey = null;
        this.createForm.Resolve = resolve;
        this.createDialog = true;
        this.searchClient();
        this.searchService();
      });
    },
    async assign(data, userRole, cb) {
      this.formData = data;
      this.cb = cb;
      this.userRole = userRole;
      this.assigned = null;
      this.reason = null;
      this.staffsOptions = [];
      this.assignDialog = true;
      await this.searchStaff();
    },
    confirmCRB() {
      this.crbForm.Resolve(this.crbForm.Form);
    },
    async crb(data, cb) {
      if (!cb) {
        this.crbForm.Dialog = false;
        return;
      }

      this.crbForm.Dialog = true;
      this.crbForm.Form = {
        CertificatePrefix: data.CertificatePrefix,
      };
      this.crbForm.Resolve = cb;
    },
    sucUploadCRB(response, file, field, cb) {
      let { File } = response.data;
      this.crbForm.Form[field] = File;
      cb();
    },
    sucUploadDocu(response, file, cb) {
      let { File } = response.data;
      this.attachmentName = file?.name || File;
      this.reasonAttachment = File;
      cb();
    },
    uploadUrl(apiLink) {
      return formulateUrl(apiLink);
    },
    confirmReject() {
      if (this.isRequiredReason && !this.reason) {
        this.$message.error(this.$t.translate("LBL_REASON_REQUIRED"));
        return;
      }

      if (this.isRequiredTranx && !this.reason) {
        this.$message.error(this.$t.translate("LBL_TRANSACTION_NO_REQUIRED"));
        return;
      }

      if (this.cb)
        this.cb(
          this.reason,
          this.reasonAttachment,
          this.specialType,
          this.paymentDate,
          this.paymentMethod,
          this.payType
        );
      this.rejectDialogVisible = false;
    },
    resetReject() {
      this.buttonName = "BTN_CONFIRM";
      this.dialogTitle = "TTL_CONFIRM_ACTION";
    },
    selectPayType({ IsEnabled, PayType }) {
      if (!IsEnabled) return;
      this.payType = PayType;
    },
    confirmInput() {
      if (!this.inputData) {
        this.$message.error(this.$t.translate("LBL_INPUT_IS_REQUIRED"));
        return;
      }
      this.inputDialogVisible = false;
      if (this.cb) this.cb(this.inputData);
    },
    async inputNow(data, action, cb) {
      this.cb = cb;
      this.currentData = data;
      this.currentAction = action;

      this.inputData = null;
      this.inputDialogVisible = true;
    },
    async reject(cb, message = null, canUpload = false, data = {}) {
      let { IsAllowOnlinePayment } = store.getters["config/getData"]?.envConf;
      let { alertMapping, validActionMapping } = extendedConfigs;
      this.cb = cb;
      this.resetReject();

      this.currentData = data;
      this.currentAction = data?.action || null;
      this.isRequiredReason = data?.isRequiredReason ? true : false;
      this.isRequiredTranx = data?.isRequiredTranx ? true : false;

      this.message = message;
      this.paymentDate = null;
      this.reason = null;
      this.reasonAttachment = null;
      this.canUpload = canUpload;
      this.image =
        data?.action == "TOPAID" && data?.PaymentProof?.DownloadToken
          ? data.PaymentProof.DownloadToken
          : null;

      this.specialType = 1;
      this.ableToConvert = data?.action == "SPECIAL" ? true : false;

      let validAction = ["APPROVE_PROGRESS"];
      let isValidAction = validAction.indexOf(data?.action) > -1;

      if (isValidAction && data?.CurrentStep && validActionMapping[data.CurrentStep]) {
        this.buttonName = validActionMapping[data.CurrentStep].button;
        this.dialogTitle = validActionMapping[data.CurrentStep].title;

        if (data?.CurrentStep == "COO" && data?.Type == "STATEMENT") {
          this.buttonName = "BTN_APPROVE";
          this.dialogTitle = "BTN_APPROVE_RECOMMENDATION";
        }
      }

      if (data?.action && alertMapping[data.action]) {
        this.buttonName = alertMapping[data.action]?.button || "BTN_CONFIRM";
        this.dialogTitle = alertMapping[data.action]?.title || "TTL_CONFIRM_ACTION";
      }

      this.paymentMethod = 1;

      if (this.currentAction == "PAY_NOW" && IsAllowOnlinePayment && this.isHaveAmount) {
        let res = await get("/invoice/paytypes", { ApplicationKey: data.ApplicationKey });
        this.paymentMethods = res || [];
        this.payType = null;
        if (res.filter((x) => x.IsEnabled).length) {
          this.paymentMethod = 0;
        }
      }

      this.rejectDialogVisible = true;
    },
  },
};
</script>

<style scoped>
.payment-method {
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 10px;
  border: 2px solid #e9e9e9;
  border-radius: 3px;
  margin-bottom: 10px;
  cursor: pointer;
}
.payment-method.disabled {
  opacity: 0.6;
}
.payment-method.selected {
  border-color: #f1832d;
}
.payment-method > div {
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.qr-code-image {
  width: 350px;
  padding-top: 350px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.payment-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.payment-method.static {
  border: none;
  cursor: default;
}
.payment-iframe-qr {
  width: 200px;
  height: 215px;
  margin-top: 15px;
}
</style>
