<script setup>
import { ref } from "vue";
import { formulateUrl, get, post, isValidResponse } from "@/utils/axios";
import { flow } from "@/utils/flow";

const emit = defineEmits(["selected"]);
const props = defineProps({
  data: Object,
});

let dropdown = ref("dropdown");
let loading = ref(false);
let options = ref([]);
let applicationData = ref(null);

let clicked = async () => {
  loading.value = true;
  let res = await get(
    "/application/view",
    {
      ApplicationKey: props.data.ApplicationKey,
    },
    true
  );
  loading.value = false;

  if (res?.Application) {
    applicationData.value = res.Application;
    options.value = flow.statusOptions(res.Application);
  }
};

let setCMD = (cmd) => {
  emit("selected", cmd, applicationData.value);
};
</script>

<template>
  <el-dropdown
    ref="dropdown"
    trigger="click"
    @visible-change="clicked"
    :hide-on-click="true"
    placement="left"
    @command="(comd) => setCMD(comd)"
  >
    <el-button type="primary" size="mini">
      <i class="el-icon-arrow-down"></i>
    </el-button>
    <template #dropdown>
      <el-dropdown-menu class="custom-dropdown-menu">
        <template v-for="item in options" :key="item.command">
          <el-dropdown-item :command="item.command">{{
            $t.translate(item.label)
          }}</el-dropdown-item>
        </template>

        <el-dropdown-item v-if="loading" disabled>{{
          $t.translate("LBL_LOADING")
        }}</el-dropdown-item>
        <el-dropdown-item v-if="!loading && !options.length" disabled>{{
          $t.translate("LBL_NO_OPTIONS")
        }}</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
<style scoped>
.custom-dropdown-menu {
  width: 250px;
}
</style>
